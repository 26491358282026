import { ExpandedState, Row, RowSelectionState, functionalUpdate } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  capitalize,
  debounce,
  flatMap,
  groupBy,
  isArray,
  isEmpty,
  isEqual,
  mapValues,
  merge,
  set,
  sumBy
} from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Button, Icon, SearchWithAction, Tooltip, Truncator } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import Select from '~/components/v2/inputs/Select';
import { ColumnDef, DataTable } from '~/components/v3';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import {
  BulkNamespaceOptionFragment,
  BulkNamespaceSelection,
  BulkSelectedNamespace,
  BulkSourceSchemaDocument,
  BulkSourceSchemaQuery,
  BulkSourceSchemaQueryVariables,
  FieldSyncType,
  SelectionState,
  Term
} from '~/generated/graphql';
import { useLazyContinuationQuery, useToggle } from '~/hooks';
import { cn } from '~/lib/utils';
import { BulkSyncForm, fieldTypeIconName, searchByNameAndId } from '~/utils';
import { StageCard } from '../../syncs/sync-config';
import {
  EBulkEntityType,
  IBulkField,
  IBulkNamespace,
  IBulkSchema,
  SchemaOrFieldKey,
  getEntitiesFromPath,
  getNamespaceState,
  getNamespaceUpdateFromState,
  getPathFromEntities,
  namespacesToSelection
} from '../components/BulkNamespaceUtil';
import { BulkFilter, BulkFilters } from './BulkFilters';
import { FieldRowMenu } from './FieldRowMenu';
import { SchemaRowMenu } from './SchemaRowMenu';
import { CutoffFilter, CutoffFilters } from './CutoffFilters';

type StageBulkObjectProps = {
  namespaceOptions: BulkNamespaceOptionFragment[];
  selectedNamespaces: BulkSelectedNamespace[];
  setSelectedNamespaces: (namespaces: BulkSelectedNamespace[]) => void;
  newNamespaces: BulkNamespaceSelection[];
  step: number;
  bulkSyncSchemaLabel?: Term;
  bulkSyncSchemaHeader?: Term;
  refreshBtn: React.ReactNode;
  loading: boolean;
};
interface ServerParams {
  includeFields?: boolean;
  syncType?: FieldSyncType;
}
interface FilterOption {
  label: string;
  value: string;
  clientFilter?: (row: Row<IBulkField | IBulkSchema | IBulkNamespace>, search?: string) => boolean;
  serverParams?: ServerParams;
  placeholder?: string;
}

export function StageBulkObjects({
  namespaceOptions,
  selectedNamespaces,
  setSelectedNamespaces,
  newNamespaces,
  step,
  bulkSyncSchemaLabel = { singular: 'object', plural: 'objects' },
  bulkSyncSchemaHeader,
  refreshBtn,
  loading = false
}: StageBulkObjectProps) {
  const { control } = useFormContext<BulkSyncForm>();
  const source = useWatch({ control, name: 'source' });
  const destination = useWatch({ control, name: 'destination' });
  const dataCutoffTimestamp = useWatch({ control, name: 'dataCutoffTimestamp' });

  const [namespaces, setNamespaces] = useState<IBulkNamespace[]>(
    getNamespaceState(namespaceOptions, selectedNamespaces)
  );
  const isNamespaceRoot = useMemo(
    () => namespaces?.length === 1 && !namespaces[0].id,
    [namespaces]
  );

  useEffect(() => {
    if (!selectedNamespaces?.length) {
      setRowSelection({});
      setExpanded({});
      setSearch('');
    }
  }, [source]);

  useEffect(() => {
    const newNamespaces = getNamespaceState(namespaceOptions, selectedNamespaces, namespaces);
    setNamespaces(newNamespaces);
    if (newNamespaces.length === 1) {
      if (newNamespaces[0].schemas.length === 1) {
        const schema = newNamespaces[0].schemas[0];
        handleLoadFields(schema);
        const newExpanded = merge(expanded, { [namespaces[0].path]: true, [schema.path]: true });
        setExpanded(newExpanded);
        return;
      }
      if (namespaces[0]) {
        setExpanded(merge(expanded, { [namespaces[0].path]: true }));
      }
    }
  }, [namespaceOptions]);

  const [search, setSearch] = useState('');
  const [isSearchDropdownOpen, toggleSearchDropdownOpen] = useToggle();
  const handleResetSearch = useCallback(() => setSearch(''), [setSearch]);
  const searchFilterOptions = useMemo<FilterOption[]>(
    () => [
      {
        label: capitalize(bulkSyncSchemaLabel.plural),
        value: 'objects',
        clientFilter: (row, search) =>
          searchByNameAndId(
            row.original.bulkEntityType === EBulkEntityType.FIELD
              ? row.getParentRow().original
              : row.original,
            search
          ),
        placeholder: `Search all ${bulkSyncSchemaLabel.plural}...`
      },
      {
        label: 'Fields',
        value: 'fields',
        clientFilter: (row, search) =>
          !search ||
          (row.original.bulkEntityType === 'FIELD'
            ? searchByNameAndId(row.original, search)
            : false),
        placeholder: 'Search all fields...'
      }
    ],
    [search, bulkSyncSchemaLabel]
  );
  const [searchFilter, setSearchFilter] = useState<FilterOption>(searchFilterOptions[0]);

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    namespacesToSelection(selectedNamespaces)
  );

  const visibilityFilterOptions = useMemo<FilterOption[]>(() => {
    const filters: FilterOption[] = [
      { label: 'All objects', value: 'all', clientFilter: () => true },
      {
        label: 'Selected objects',
        value: 'selected',
        clientFilter: row => {
          const { namespace, schema } = getEntitiesFromPath(namespaces, row.original.path);
          if (schema) {
            return (
              schema?.fields.some(field => rowSelection[field.path]) ||
              schema.selectionState === SelectionState.All ||
              schema.selectionState === SelectionState.Partial
            );
          } else if (namespace) {
            return namespace.schemas.some(
              schema =>
                schema?.fields.some(field => rowSelection[field.path]) ||
                schema.selectionState === SelectionState.All ||
                schema.selectionState === SelectionState.Partial
            );
          }
          return true;
        }
      }
    ];
    if (destination?.properties?.supportsPartitions) {
      filters.push({
        label: 'Partition keys',
        value: 'partitionKey',
        clientFilter: row => !!(row.original as IBulkSchema).partitionKey
      });
    }
    if (source?.properties?.supportsSlowMode) {
      filters.push(
        {
          label: 'Incremental sync fields',
          value: 'incremental',
          clientFilter: row => !(row.original as IBulkField).slowMode,
          serverParams: {
            includeFields: true,
            syncType: FieldSyncType.Nonincremental
          }
        },
        {
          label: 'Non-incremental sync fields',
          value: 'nonIncremental',
          clientFilter: row => !!(row.original as IBulkField).slowMode,
          serverParams: {
            includeFields: true,
            syncType: FieldSyncType.Nonincremental
          }
        }
      );
    }
    if (source.connection.type.id === 'salesforce') {
      filters.push({
        label: 'Formula fields',
        value: 'formulaFields',
        clientFilter: row => !!(row.original as IBulkField).isCalculated,
        serverParams: {
          includeFields: true,
          syncType: FieldSyncType.Calculated
        }
      });
    }
    filters.push({
      label: 'Obfuscated columns',
      value: 'obfuscate',
      clientFilter: row => !!(row.original as IBulkField).obfuscate,
      serverParams: { includeFields: true }
    });

    return filters;
  }, [namespaces, rowSelection, source, destination]);

  const [visibilityFilter, setVisibilityFilter] = useState<FilterOption>(
    visibilityFilterOptions[0]
  );

  const counts = useMemo(() => {
    const allSchemas = flatMap(namespaces, ({ schemas }) => schemas);
    const allFields = flatMap(allSchemas, ({ fields }) => fields);
    const totalPartitionKeys =
      sumBy(
        allSchemas,
        schema => schema.partitionKey && schema.fields.some(field => rowSelection[field.path]) && 1
      ) || 0;
    const totalNonIncremental =
      sumBy(
        allSchemas,
        schema => schema.fields?.some(field => rowSelection[field.path] && field.slowMode) && 1
      ) || 0;
    const totalObfuscated =
      sumBy(allFields, field => rowSelection[field.path] && field.obfuscate && 1) || 0;
    const totalOptions = flatMap(namespaces, namespace => namespace.schemas).length;
    const totalSelections = flatMap(selectedNamespaces, namespace => namespace.schemas).length;

    return {
      totalPartitionKeys,
      totalNonIncremental,
      totalObfuscated,
      totalOptions,
      totalSelections
    };
  }, [namespaces, selectedNamespaces, rowSelection]);

  // TODO @poindexd: this was used for new fields discovered during refresh. Still needed?
  // When refreshing the schema, detect any new fields and update local state
  // New fields will be consumed once the server selection has updated
  // const [newServerFields, setNewServerFields] = useState<string[]>();
  // useEffect(() => {
  //   const newFields = getNewFields(namespaces, namespaceOptions);
  //   setNewServerFields(newFields);
  //   setNamespaces(getNamespaceState(namespaceOptions, selectedNamespaces, namespaces));
  // }, [namespaceOptions]);

  // When the server selection is updated, add new selected fields to local state,
  // then update the parent form state with the updated selection
  // useEffect(() => {
  //   const serverSelection = getServerSelection(newNamespaces, newServerFields);
  //   const newRowSelection = { ...rowSelection, ...serverSelection };
  //   setRowSelection(newRowSelection);
  //   updateSelectedNamespaces(
  //     getNamespaceState(namespaceOptions, newNamespaces, namespaces),
  //     newRowSelection
  //   );
  // }, [newNamespaces]);

  const updateSelectedNamespaces = (namespaces: IBulkNamespace[], selection: RowSelectionState) => {
    const newNamespaces = getNamespaceUpdateFromState(namespaces, selection);
    setSelectedNamespaces(newNamespaces);
  };

  const setNamespaceValue = (path: string, key: SchemaOrFieldKey, value: unknown) => {
    const newNamespaces = [...namespaces];
    const { schema, field } = getEntitiesFromPath(newNamespaces, path);
    handleLoadFields(schema);
    set(field ?? schema, key, value);
    setNamespaces(newNamespaces);
    updateSelectedNamespaces(newNamespaces, rowSelection);
  };

  const handleRowSelectionChange = (selection: RowSelectionState) => {
    setRowSelection(selection);
    updateSelectedNamespaces(namespaces, selection);
  };

  const [loadSchemaFields] = useLazyContinuationQuery<
    BulkSourceSchemaQuery,
    BulkSourceSchemaQueryVariables
  >(BulkSourceSchemaDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const namespace = data?.bulkSourceForConnection?.namespaces?.[0];
      const schema = data?.bulkSourceForConnection?.namespaces?.[0]?.schemas?.[0];
      const { schema: existingSchema } = getEntitiesFromPath(
        namespaces,
        getPathFromEntities(namespace, schema)
      );
      const newNamespaces = namespaces.map(n => ({
        ...n,
        schemas: n.schemas.map(s =>
          s.id !== schema.id
            ? s
            : {
                ...s,
                selectionState: null,
                fields:
                  s.id !== schema.id
                    ? s.fields
                    : schema?.fields.map<IBulkField>(field => {
                        const existing = existingSchema.fields?.find(f => f.id === field.id);
                        return {
                          ...field,
                          obfuscate: existing?.obfuscate ?? false,
                          bulkEntityType: EBulkEntityType.FIELD,
                          path: getPathFromEntities(namespace, schema, field)
                        };
                      })
              }
        )
      }));
      const newRowSelection = { ...rowSelection };
      if (existingSchema.selectionState === 'all') {
        schema?.fields?.forEach(
          field => (newRowSelection[getPathFromEntities(namespace, schema, field)] = true)
        );
      }
      setNamespaces(newNamespaces);
      setRowSelection(newRowSelection);
    }
  });

  const handleLoadFields = async (row: IBulkField | IBulkSchema | IBulkNamespace) => {
    if (row.bulkEntityType === EBulkEntityType.SCHEMA) {
      const r = row as IBulkSchema;
      if (!isEmpty(r.selectionState)) {
        const { namespace, schema } = getEntitiesFromPath(namespaces, row.path);
        if (!namespace || !schema) {
          return;
        }
        loadSchemaFields({
          variables: {
            connectionId: source.connection.id,
            namespaceId: namespace.id,
            schemaId: schema.id,
            continuation: uuid(),
            includeFields: true
          }
        });
      }
    }
  };

  const [refetchingSchema, setRefetchingSchema] = useState<boolean>(false);
  const [refetchSchema] = useLazyContinuationQuery<
    BulkSourceSchemaQuery,
    BulkSourceSchemaQueryVariables
  >(BulkSourceSchemaDocument, {
    onCompleted: data => {
      const newNamespaces = getNamespaceState(
        namespaceOptions,
        data.bulkSourceForConnection.namespaces as [],
        namespaces
      );

      setNamespaces(newNamespaces);
      updateSelectedNamespaces(newNamespaces, rowSelection);
      setRefetchingSchema(false);
    },
    onError: () => {
      setRefetchingSchema(false);
    }
  });

  const columns = useMemo<ColumnDef<IBulkField | IBulkSchema | IBulkNamespace>[]>(
    () => [
      {
        id: 'schema',
        accessorKey: 'schema',
        header: () => (
          <span>{`${bulkSyncSchemaHeader?.plural || bulkSyncSchemaLabel?.plural || 'name'}`}</span>
        ),
        accessorFn: row => row.id,
        cell: ({ row }) => (
          <Truncator content={row.original.name}>
            <p
              className={clsx(
                'hide-native-tooltip cursor-default truncate text-gray-800',
                row.original.bulkEntityType !== 'FIELD' && 'font-medium'
              )}
            >
              {row.original.name}
            </p>
          </Truncator>
        ),
        size: 125,
        sortDescFirst: false,
        enableGlobalFilter: true
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: () => null,
        cell: ({ row }) => {
          if (row.original.bulkEntityType === 'SCHEMA') {
            const schema = row.original as IBulkSchema;
            const trackingField = schema.fields?.find(field => field.id === schema.trackingField);
            return (
              !!trackingField && (
                <span className="text-gray-500">Tracking field: {trackingField.id}</span>
              )
            );
          }

          if (row.original.bulkEntityType === 'FIELD') {
            const field = row.original as IBulkField;
            return (
              <span className="flex flex-row content-center items-center space-x-1 text-gray-500">
                <Icon name={fieldTypeIconName(field.type)} />
                <Tooltip disabled={!field.isCalculated} content="Formula field">
                  <p className="hide-native-tooltip cursor-default truncate text-left text-gray-500">
                    {field.type}
                  </p>
                </Tooltip>
              </span>
            );
          }

          return null;
        },
        enableGlobalFilter: false,
        size: 75
      },
      {
        id: 'systemName',
        accessorKey: 'id',
        header: () => <span>System name</span>,
        cell: ({ row }) => (
          <Truncator content={row.original?.id}>
            <p className="hide-native-tooltip cursor-default truncate text-left text-gray-800">
              {row.original?.outputName || row.original?.id}
            </p>
          </Truncator>
        ),
        isVisible: !source?.properties?.hideSystemName,
        size: 100
      },
      {
        id: 'actions',
        accessorKey: 'actions',
        header: () => (
          <div className="flex flex-row content-center items-center justify-end space-x-2 text-gray-500 opacity-50">
            {source?.properties?.supportsIncremental && (
              <TooltipIcon
                message={`${counts.totalNonIncremental} ${
                  counts.totalNonIncremental === 1 ? 'object' : 'objects'
                } will be synced non-incrementally`}
                icon={<Icon name="Turtle" size="sm" />}
              />
            )}
            {destination?.properties?.supportsPartitions && (
              <TooltipIcon
                message={`${counts.totalPartitionKeys} tables with partition keys`}
                icon={<Icon name="PartitionKey" size="sm" />}
              />
            )}
            <TooltipIcon
              message={`${counts.totalObfuscated} ${
                (bulkSyncSchemaLabel?.singular || 'object') === 'table' ? 'column' : 'field'
              }${counts.totalObfuscated === 1 ? '' : 's'} obfuscated`}
              icon={<Icon name="Astrisk" size="sm" />}
            />
            <div className="h-5 w-5"></div>
          </div>
        ),
        cell: ({ row }) => {
          if (row.original.bulkEntityType === 'SCHEMA') {
            return (
              <SchemaRowMenu
                row={row as Row<IBulkSchema>}
                connectionId={source.connection.id}
                setNamespaceValue={setNamespaceValue}
                supportsPartitionKeys={destination?.properties?.supportsPartitions}
                supportsTrackingFields={source?.properties?.supportsTrackingFields}
                vocabulary={destination?.properties?.vocabulary}
              />
            );
          }
          if (row.original.bulkEntityType === 'FIELD') {
            return (
              <FieldRowMenu row={row as Row<IBulkField>} setNamespaceValue={setNamespaceValue} />
            );
          }
          return null;
        },
        size: 75
      }
    ],
    [counts, source, destination, bulkSyncSchemaHeader, bulkSyncSchemaLabel]
  );

  const handleSearchFilterChange = (filter: FilterOption) => {
    setSearchFilter(filter);
    handleSearchChange(search, filter);
  };

  const handleSearchChange = (value: string, filter?: FilterOption) => {
    if ((filter ?? searchFilter).value === 'fields' && value.length >= 3) {
      setRefetchingSchema(true);
      refetchSchema({
        variables: {
          connectionId: source.connection.id,
          continuation: uuid(),
          includeFields: true,
          fieldFilters: { search: value },
          schemaFilters: { fieldSearch: value }
        }
      });
    }

    setSearch(value);
  };

  const debouncedSearchChange = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, [searchFilter]);

  const DEFAULT_PARAMS = { includeFields: false };
  const [lastParams, setLastParams] = useState<ServerParams>(DEFAULT_PARAMS);
  const handleVisibilityFilterChange = (filter: FilterOption) => {
    const params = merge(DEFAULT_PARAMS, filter.serverParams);
    if (!isEqual(lastParams, params)) {
      setRefetchingSchema(true);
      refetchSchema({
        variables: {
          connectionId: source.connection.id,
          continuation: uuid(),
          includeFields: params.includeFields ?? false,
          fieldFilters: { syncType: params.syncType, search: null },
          schemaFilters: { hasFieldsOfSyncType: params.syncType, search: null }
        }
      });
      setLastParams(params);
    }
    setVisibilityFilter(filter);
  };

  const initialFilters = selectedNamespaces.reduce((acc, namespace) => {
    namespace.schemas.forEach(schema => {
      acc.push(...schema.filters.map(filter => ({ ...filter, schemaID: schema.id })));
    });
    return acc;
  }, []);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<BulkFilter[]>([]);
  const [activeFilterIndex, setActiveFilterIndex] = useState<number>(null);
  const [filtersHydrated, setFiltersHydrated] = useState<boolean>(false);
  const onFiltersChange = (filters: BulkFilter[], update?: boolean) => {
    setFilters(filters);
    if (!update) {
      return;
    }
    const schemaFilters = mapValues(
      groupBy(
        filters.filter(f => f?.schema?.id),
        filter => filter.schema.id
      ),
      filters =>
        filters.map(filter => ({
          fieldID: filter.field.id,
          function: filter.function,
          value: filter.value
        }))
    );

    const newNamespaces = namespaces.map<IBulkNamespace>(namespace => ({
      ...namespace,
      schemas: namespace.schemas.map(schema => ({
        ...schema,
        filters: schemaFilters[schema.id] ?? []
      }))
    }));

    setNamespaces(newNamespaces);
    updateSelectedNamespaces(newNamespaces, rowSelection);
  };

  const handleCutoffFiltersChange = (newFilters: CutoffFilter[]) => {
    const cutoffs = groupBy(newFilters, filter => filter?.schema?.id);

    const newNamespaces = namespaces.map<IBulkNamespace>(namespace => ({
      ...namespace,
      schemas: namespace.schemas.map(schema => ({
        ...schema,
        dataCutoffTimestamp: cutoffs[schema?.id]?.[0]?.dataCutoffTimestamp,
        disableDataCutoff: !!cutoffs[schema?.id]?.[0]?.disableDataCutoff
      }))
    }));

    setNamespaces(newNamespaces);
    updateSelectedNamespaces(newNamespaces, rowSelection);
  };

  const initialCutoffFilters = useMemo(
    () =>
      namespaces?.reduce(
        (acc, namespace) =>
          acc.concat(
            namespace.schemas
              ?.filter(schema => !!schema.dataCutoffTimestamp || schema.disableDataCutoff)
              .map(schema => ({
                schema,
                namespace: namespace.id ? namespace : null,
                dataCutoffTimestamp: schema.dataCutoffTimestamp,
                disableDataCutoff: schema.disableDataCutoff
              }))
          ),
        []
      ) ?? [],
    []
  );

  const filterableNamespaces = useMemo(
    () =>
      namespaces
        .map(namespace => ({
          ...namespace,
          schemas: namespace.schemas
            .map(schema => ({
              ...schema,
              fields: schema.fields.filter(
                field =>
                  !rowSelection || rowSelection[getPathFromEntities(namespace, schema, field)]
              )
            }))
            .filter(schema => schema.fields.length || schema.selectionState === 'all')
        }))
        .filter(namespace => namespace.schemas.length),
    [namespaces, rowSelection]
  );

  return (
    <StageCard
      hasStickyHeader={true}
      header={`Choose ${source?.connection?.name || 'source'} ${
        bulkSyncSchemaLabel?.plural || 'objects'
      }`}
      step={step}
    >
      <div className="space-y-6 px-6">
        <div className="flex items-center justify-between">
          <SearchWithAction
            //debounce
            onChange={debouncedSearchChange}
            onReset={handleResetSearch}
            stopEscHotKey={false}
            wrapperStyles="w-1/2 overflow-hidden"
            placeholder={searchFilter.placeholder}
            endAction={
              <DropdownMenu open={isSearchDropdownOpen} onOpenChange={toggleSearchDropdownOpen}>
                <DropdownMenuTrigger asChild>
                  <button
                    className={cn(
                      'flex h-8 max-w-[6.625rem] items-center justify-between rounded-l-full border-r border-gray-300 p-1 focus:outline-none',
                      isSearchDropdownOpen
                        ? 'bg-gray-200'
                        : 'bg-gray-100 hover:bg-gray-200 active:bg-gray-200'
                    )}
                  >
                    <p className="ml-2 max-w-[6rem] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium">
                      {searchFilter.label}
                    </p>
                    <span className="ml-1 mr-0.5">
                      <Icon
                        name="SelectSingle"
                        className={cn(
                          'h-5 w-5 transform text-gray-500',
                          isSearchDropdownOpen && 'rotate-180'
                        )}
                      />
                    </span>
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  portal={false}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {searchFilterOptions.map(option => (
                    <DropdownMenuItem
                      key={option.value}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleSearchDropdownOpen();
                        handleSearchFilterChange(
                          searchFilterOptions.find(o => option.value === o.value)
                        );
                      }}
                    >
                      {option.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            }
          />
          {refreshBtn}
        </div>
        <div className="h-[400px] overflow-hidden">
          <DataTable<IBulkNamespace | IBulkSchema | IBulkField>
            data={isNamespaceRoot ? namespaces[0].schemas : namespaces}
            columns={columns}
            loading={(loading || refetchingSchema) && !isEmpty(activeFilterIndex)}
            estimateSize={() => 52.55}
            // Expansion
            expanded={expanded}
            onExpandedChange={setExpanded}
            onRowExpanded={handleLoadFields}
            getRowId={row => row.path}
            getSubRows={row => (row as IBulkNamespace).schemas ?? (row as IBulkSchema).fields}
            // Filtering
            globalFilter={row =>
              (!visibilityFilter.clientFilter || visibilityFilter.clientFilter(row)) &&
              (!searchFilter.clientFilter || searchFilter.clientFilter(row, search))
            }
            onGlobalFilterChange={() => {}}
            // Selection
            rowSelection={rowSelection}
            onRowSelectionChange={fn =>
              handleRowSelectionChange(functionalUpdate(fn, rowSelection))
            }
            enableSorting={false}
            getSelectionState={row => row.selectionState}
            setSelectionState={(row, state) => {
              const rows = isArray(row) ? row : [row];
              const newNamespaces = [...namespaces];

              rows.forEach(row => {
                const { schema, field } = getEntitiesFromPath(newNamespaces, row.path);
                set(field ?? schema, 'selectionState', state);
                set(field ?? schema, 'fields', []);
              });

              setNamespaces(newNamespaces);
              updateSelectedNamespaces(newNamespaces, rowSelection);
            }}
            // Options
            emptyMessage={`No ${bulkSyncSchemaLabel?.plural || 'objects'} ${
              visibilityFilter.value === 'selected' ? 'selected' : 'detected'
            }`}
            classNames={{ wrapper: 'h-full' }}
            maxLeafRowFilterDepth={2}
            showLoadingWhenRowsExist={true}
          />
        </div>
        {!loading && (
          <div className="flex h-8 w-full items-center justify-between">
            <div className="flex flex-row items-center justify-start space-x-3">
              <Select
                variant="filled"
                options={visibilityFilterOptions}
                onChange={v => handleVisibilityFilterChange(v)}
                value={visibilityFilter}
                inputWidth="w-[26ch]"
                portal={false}
              />
              <p className="text-gray-600">{`${counts.totalSelections} out of ${
                counts.totalOptions
              } ${bulkSyncSchemaLabel?.plural || 'objects'} selected`}</p>
            </div>

            {!filters?.length &&
              filtersHydrated &&
              !initialCutoffFilters?.length &&
              !dataCutoffTimestamp &&
              !showFilters && (
                <Tooltip
                  content="Select tables above to enable column filtering"
                  disabled={!!counts.totalSelections}
                >
                  <div>
                    <Button
                      theme="ghost"
                      iconEnd="PlusCircle"
                      onClick={() => setShowFilters(true)}
                      disabled={!counts.totalSelections}
                      className="pointer-events-auto"
                    >
                      Add filters
                    </Button>
                  </div>
                </Tooltip>
              )}
          </div>
        )}

        {(filters?.length ||
          initialFilters?.length ||
          initialCutoffFilters?.length ||
          dataCutoffTimestamp ||
          showFilters) &&
          source.properties.supportsDataCutoffTimestamp && (
            <CutoffFilters
              namespaces={filterableNamespaces}
              onCutoffFiltersChange={handleCutoffFiltersChange}
              initialCutoffFilters={initialCutoffFilters}
            />
          )}
        <BulkFilters
          connectionId={source.connection.id}
          namespaces={filterableNamespaces}
          bulkSyncSchemaLabel={bulkSyncSchemaLabel}
          refetchSchema={refetchSchema}
          handleLoadFields={handleLoadFields}
          initialFilters={initialFilters}
          onChange={onFiltersChange}
          filters={filters}
          hydrated={filtersHydrated}
          setHydrated={setFiltersHydrated}
          activeFilterIndex={activeFilterIndex}
          setActiveFilterIndex={setActiveFilterIndex}
          showFilters={showFilters || !!initialCutoffFilters?.length || !!dataCutoffTimestamp}
        />
      </div>
    </StageCard>
  );
}
