import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, LinkButton, MyInput } from '../../components';
import { useToggle } from '../../hooks';
import { LoginWrapper } from './login-wrapper';

type Response = Record<'google' | 'microsoft' | 'sso' | 'deployment_sso', boolean>;

interface FormValues {
  domain: string;
}

export function Login() {
  const [showSSO, toggleShowSSO] = useToggle();
  const [methods, setMethods] = React.useState<Response | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const controller = new AbortController();
    if (!controller.signal.aborted) {
      setLoading(true);
      fetch(`${import.meta.env.VITE_API_URL || ''}/auth/methods`, {
        signal: controller.signal,
        method: 'GET',
        cache: 'no-cache',
        redirect: 'manual',
        credentials: 'include',
        referrerPolicy: 'no-referrer'
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then(data => {
          setMethods(data as Response);
          setLoading(false);
        })
        .catch(error => {
          if (!controller.signal.aborted) {
            if (typeof error === 'string') {
              setError(error);
            }
            setError(JSON.stringify(error, null, 2));
            setLoading(false);
          }
        });
    }
    return () => {
      controller.abort();
      setLoading(false);
    };
  }, []);

  const { handleSubmit, register, reset } = useForm<FormValues>();

  function handleGoogle() {
    window.location.href = `${import.meta.env.VITE_API_URL || ''}/login/google`;
  }

  function handleMicrosoft() {
    window.location.href = `${import.meta.env.VITE_API_URL || ''}/sso/start?microsoftSSO=true`;
  }

  const handleOrgSSO: SubmitHandler<FormValues> = ({ domain }) => {
    window.location.href = `${import.meta.env.VITE_API_URL || ''}/sso/start?domain=${domain}`;
  };

  const startSSO = () => {
    if (methods?.deployment_sso) {
      window.location.href = `${import.meta.env.VITE_API_URL || ''}/sso/start`;
      return;
    }
    toggleShowSSO();
  };

  function handleBackToPicker() {
    toggleShowSSO();
    reset();
  }

  return (
    <LoginWrapper error={error} loading={loading}>
      <div className="w-54 mx-auto grid justify-items-stretch sm:w-full">
        {showSSO ? (
          <>
            <form onSubmit={handleSubmit(handleOrgSSO)}>
              <div className="w-72">
                <MyInput
                  autoFocus={true}
                  {...register('domain', {
                    required: 'Domain cannot be empty'
                  })}
                  label="Sign in with your organization"
                  placeholder="Your company domain..."
                  description="Such as, example.com"
                />
              </div>
              <Button type="submit" theme="primary" className="mt-3">
                Continue with domain
              </Button>
            </form>
            <LinkButton onClick={handleBackToPicker} className="mt-4">
              ← Back
            </LinkButton>
          </>
        ) : (
          <>
            {(!methods || methods?.google) && (
              <Button theme="primary" onClick={handleGoogle}>
                <span className="flex items-center">
                  <span className="mr-1.75 grid h-6 w-6 place-items-center rounded bg-white">
                    <GoogleG />
                  </span>
                  Continue with Google
                </span>
              </Button>
            )}
            {(!methods || methods?.microsoft) && (
              <Button className="mt-4" onClick={handleMicrosoft}>
                <span className="flex items-center">
                  <span className="mr-1.75 grid h-6 w-6 place-items-center">
                    <MicrosoftSquare />
                  </span>
                  Continue with Microsoft
                </span>
              </Button>
            )}
            {(!methods || methods?.sso || methods?.deployment_sso) && (
              <LinkButton onClick={startSSO} className="mt-4">
                Using Single Sign-On?
              </LinkButton>
            )}
          </>
        )}
      </div>
      <a
        href="https://www.polytomic.com/terms"
        rel="noopener noreferrer"
        target="_blank"
        className="link absolute bottom-6 right-6 text-xs"
      >
        Terms
      </a>
    </LoginWrapper>
  );
}

const GoogleG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="h-5 w-5">
    <defs>
      <path
        id="a"
        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
      />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
    <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
    <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
    <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
  </svg>
);

const MicrosoftSquare = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5">
    <path d="M9.50348 0.000183105H0V9.50362H9.50348V0.000183105Z" fill="#F25022" />
    <path d="M19.9963 0H10.4929V9.50335H19.9963V0Z" fill="#7FBA00" />
    <path d="M9.50335 10.4967H0V20H9.50335V10.4967Z" fill="#00A4EF" />
    <path d="M19.9963 10.4967H10.4929V20H19.9963V10.4967Z" fill="#FFB900" />
  </svg>
);
