import { IconName } from '~/assets';
import { Tooltip } from '../tooltip';
import { Icon } from '../Icon';
import { TippyProps } from '@tippyjs/react/headless';
import { VariantProps, getClassNames } from '~/lib/utils';
import { tv } from 'tailwind-variants';
import { ReactNode } from 'react';
import { isString } from 'lodash';

const status = tv({
  slots: {
    tooltip: 'max-h-[300px] max-w-3xl overflow-auto',
    icon: 'h-4 w-4',
    text: 'text-sm'
  }
});

interface StatusProps
  extends Pick<TippyProps, 'placement' | 'interactive'>,
    VariantProps<typeof status> {
  icon?: IconName;
  tooltipContent?: ReactNode;
  children: ReactNode;
}

export function Status({
  icon,
  children,
  tooltipContent,
  placement = 'top',
  interactive,
  ...rest
}: StatusProps) {
  const classNames = getClassNames(status, rest);
  return (
    <Tooltip
      className={classNames.tooltip}
      disabled={!tooltipContent}
      content={tooltipContent}
      placement={placement}
      interactive={interactive}
    >
      <div className="inline-flex items-center gap-2">
        {icon ? (
          <Icon match={icon} className={classNames.icon} />
        ) : (
          <div className={classNames.icon} />
        )}
        {isString(children) ? <p className={classNames.text}>{children}</p> : children}
      </div>
    </Tooltip>
  );
}
