import { Switch as HeadlessSwitch } from '@headlessui/react';
import cx from 'clsx';
import * as React from 'react';

import { Icon } from '~/components';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  loading?: boolean;
}

type LabelProps =
  | { label?: never; labelPosition?: never }
  | { label: string; labelPosition?: 'left' | 'right' };

type Props = SwitchProps & LabelProps;

export const MySwitch = React.forwardRef<HTMLSpanElement, Props>(
  ({ label, className, labelPosition = '', checked, onChange, ...props }, ref) => {
    function disabledFunc() {
      return;
    }
    const labelComponent = (
      <HeadlessSwitch.Label
        className="block text-sm font-medium"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {label}
      </HeadlessSwitch.Label>
    );
    return (
      <HeadlessSwitch.Group>
        <div className={cx('inline-flex items-center space-x-2', className)}>
          {labelPosition === 'left' && labelComponent}
          <span
            ref={ref}
            className={cx('h-6', (props.disabled || props.readOnly) && 'cursor-not-allowed')}
          >
            <HeadlessSwitch
              checked={checked}
              onChange={props.loading || props.disabled || props.readOnly ? disabledFunc : onChange}
              className={cx(
                props.loading || props.disabled || props.readOnly
                  ? 'pointer-events-none bg-opacity-50'
                  : 'focus:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100',
                checked ? 'bg-green-500' : 'bg-gray-300',
                'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-150 ease-in-out focus:outline-none'
              )}
            >
              <span className="sr-only">{label}</span>
              <span
                className={cx(
                  checked ? 'bg-green translate-x-5' : 'translate-x-0 bg-white',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full ring-0 transition duration-150 ease-in-out'
                )}
              >
                <Icon name="CheckFilled" className="h-5 w-5 text-white" />
              </span>
            </HeadlessSwitch>
          </span>
          {labelPosition === 'right' && labelComponent}
        </div>
      </HeadlessSwitch.Group>
    );
  }
);
