import { useMutation } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import {
  Button,
  EditPermission,
  FrequencyOptions,
  Icon,
  MySwitch,
  Permission,
  SideBySide,
  Tooltip
} from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Dialog, useToast } from '~/components/v3';
import {
  Action,
  ActivateSyncDocument,
  ExecutionStatus,
  Frequency,
  StartSyncDocument,
  SyncFragment,
  SyncStatusFragment
} from '~/generated/graphql';
import { useBannerDispatch, useToggle } from '~/hooks';
import { RunAfterSyncDisplay } from '~/pages/syncs/run-after-sync-display';
import {
  CancelButton,
  ExecutionStatusDisplay,
  StatusLabel,
  StatusSub,
  SyncErrorCount,
  SyncRecordCount
} from '~/pages/syncs/sync-status';
import { emptyCell, getLongLocalTime } from '~/utils';

interface Props {
  sync: SyncFragment;
}

export function StatusDisplay({ sync }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [syncStatus, setSyncStatus] = React.useState<SyncStatusFragment>({
    execution: sync.currentExecution || sync.lastExecution,
    nextExecutionTime: sync.nextExecutionTime
  });
  const [isActive, setIsActive] = React.useState(sync.active);
  const [showTruncateDialog, toggleTruncateDialog] = useToggle();
  const { toast } = useToast();

  const [activateSync, { loading: activateSyncLoading }] = useMutation(ActivateSyncDocument, {
    optimisticResponse: vars => {
      return {
        activateSync: {
          id: vars.id,
          active: vars.active
        }
      };
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    update: (cache, { data }) => {
      if (!data || !data.activateSync) {
        return;
      }
      const id = data.activateSync.id;
      cache.modify({
        id: cache.identify({ __typename: 'Sync', id }),
        fields: {
          active: () => data.activateSync.active
        }
      });
    }
  });

  const [startSync, { loading: startSyncLoading }] = useMutation(StartSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  const [forceSync, { loading: forceSyncLoading }] = useMutation(StartSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  const isExecuting = syncStatus?.execution
    ? syncStatus.execution.status !== ExecutionStatus.Canceled &&
      syncStatus.execution.status !== ExecutionStatus.Completed &&
      syncStatus.execution.status !== ExecutionStatus.Failed
    : false;

  function handleToggleActive(active: boolean) {
    setIsActive(active);
    if (active) {
      toast({
        icon: <Icon match="InfoFilled" className="text-blue-400" />,
        description: 'Sync moved out of Disabled',
        duration: 3000
      });
    } else {
      toast({
        icon: <Icon match="InfoFilled" className="text-blue-400" />,
        description: 'Sync moved to Disabled',
        duration: 3000
      });
    }
    void activateSync({
      variables: { id: sync.id, active }
    });
  }

  function handleStartSync() {
    void startSync({ variables: { id: sync.id } });
  }
  function handleTestSync() {
    void startSync({ variables: { id: sync.id, opts: { test: true } } });
  }
  function handleForceResyncNoGuard() {
    void forceSync({ variables: { id: sync.id, opts: { resync: true } } });
  }
  function handleForceResync() {
    if (sync.willTruncateOnResync) {
      toggleTruncateDialog();
      return;
    }
    handleForceResyncNoGuard();
  }
  function handleTruncateConfirmation() {
    toggleTruncateDialog();
    handleForceResyncNoGuard();
  }

  return (
    <>
      <SideBySide hasSectionWrap heading="Status" maxWidth="max-w-full">
        <div className="-mr-6 space-y-4 divide-y divide-gray-300">
          <EditPermission>
            <MySwitch
              label="Enable sync"
              labelPosition="left"
              checked={isActive}
              disabled={
                activateSyncLoading ||
                (!isActive &&
                  (!sync.targetObject || sync.targetConnection?.health.status !== 'healthy'))
              }
              onChange={handleToggleActive}
            />
          </EditPermission>
          {syncStatus && (
            <>
              <div className="grid grid-cols-[6rem,1fr] gap-x-6 gap-y-4 pt-4">
                {syncStatus.execution ? (
                  <>
                    <StatusLabel text="Status">
                      <ExecutionStatusDisplay sync={sync} execution={syncStatus.execution} />
                    </StatusLabel>
                    {syncStatus.execution?.startedAt && (
                      <>
                        <StatusLabel text={isExecuting ? 'Started' : 'Last run'}>
                          <p>{getLongLocalTime(syncStatus.execution.startedAt) || emptyCell}</p>
                        </StatusLabel>
                        {syncStatus.execution?.completedAt && (
                          <StatusLabel text="Duration">
                            <p>{syncStatus.execution.formattedDuration || emptyCell}</p>
                          </StatusLabel>
                        )}
                      </>
                    )}
                    <StatusLabel text="Total records">
                      <SyncRecordCount sync={sync} syncStatus={syncStatus} />
                    </StatusLabel>
                    <SyncErrorCount sync={sync} syncStatus={syncStatus} />
                  </>
                ) : (
                  <StatusLabel text="Status">
                    <p>Never run</p>
                  </StatusLabel>
                )}
              </div>
              {sync.active && (
                <div className="grid grid-cols-[6rem,1fr] gap-x-6 pt-4">
                  <StatusLabel text="Next run">
                    <span>
                      {syncStatus.nextExecutionTime ? (
                        getLongLocalTime(syncStatus.nextExecutionTime)
                      ) : sync.schedule?.frequency !== Frequency.Runafter ? (
                        <span>{FrequencyOptions[sync.schedule?.frequency]?.label}</span>
                      ) : (
                        <RunAfterSyncDisplay
                          runAfterSyncs={[...sync.runAfterSyncs, ...sync.runAfterBulkSyncs]}
                        />
                      )}
                    </span>
                  </StatusLabel>
                </div>
              )}
            </>
          )}
        </div>
      </SideBySide>
      <SideBySide hasSectionWrap heading="Controls" maxWidth="max-w-full">
        <div className="-mr-6 divide-y divide-gray-300">
          {!isExecuting ? (
            sync.active ? (
              <div className="flex items-center space-x-2">
                <Permission type={Action.Trigger}>
                  <Button onClick={handleStartSync} disabled={startSyncLoading || forceSyncLoading}>
                    Sync now
                  </Button>
                </Permission>
                {startSyncLoading && <LoadingDots />}
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <Permission type={Action.Trigger}>
                  <Button onClick={handleTestSync} disabled={startSyncLoading || forceSyncLoading}>
                    Run test sync
                  </Button>
                </Permission>
                {startSyncLoading ? (
                  <LoadingDots />
                ) : (
                  <Tooltip
                    placement="top"
                    content={cx(
                      'Sync five random records',
                      sync.targetConnection?.name && `to ${sync.targetConnection.name}`
                    )}
                  >
                    <span className="mt-0.5">
                      <Icon
                        name="InfoFilled"
                        className="h-4 w-4 cursor-pointer text-blue-500 hover:text-blue-400"
                      />
                    </span>
                  </Tooltip>
                )}
              </div>
            )
          ) : (
            <Permission type={Action.Trigger}>
              <CancelButton sync={sync} status={syncStatus.execution?.status} />
            </Permission>
          )}
          <div className="mt-4 flex items-center space-x-2 pt-4">
            <Permission type={Action.Trigger}>
              <Button
                theme="warning"
                disabled={forceSyncLoading || startSyncLoading || isExecuting}
                onClick={handleForceResync}
                iconEnd="Refresh"
              >
                Force full resync
              </Button>
            </Permission>
            {isExecuting ? (
              <Tooltip placement="top" content="Disabled while sync is running">
                <span className="mt-0.5">
                  <Icon
                    name="InfoFilled"
                    className="h-4 w-4 cursor-pointer text-blue-500 hover:text-blue-400"
                  />
                </span>
              </Tooltip>
            ) : (
              forceSyncLoading && <LoadingDots />
            )}
          </div>
        </div>
      </SideBySide>
      {sync?.id && <StatusSub sync={sync} syncStatus={syncStatus} setSyncStatus={setSyncStatus} />}
      <Dialog
        show={showTruncateDialog}
        onDismiss={toggleTruncateDialog}
        heading="Truncate and resync?"
        actions={
          <>
            <Button onClick={toggleTruncateDialog}>Cancel</Button>
            <Button theme="warning" onClick={handleTruncateConfirmation}>
              Truncate and resync
            </Button>
          </>
        }
      >
        <p>
          <strong>WARNING:</strong>{' '}
          {sync.resyncWarning || (
            <>
              This will truncate the <strong>{sync.targetObject?.name}</strong> table then resync
              your data.
            </>
          )}
        </p>
      </Dialog>
    </>
  );
}
