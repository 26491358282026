import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Icon, InlineMessage, Label, MySwitch } from '~/components';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { NotificationsDocument, UpdateUserEmailNotificationDocument } from '~/generated/graphql';
import { useAuth, useBannerDispatch } from '~/hooks';
import { cn } from '~/lib/utils';

const wrapperStyles = 'px-3 pt-3';

interface Props {
  orgId: string;
}

enum Frequency {
  daily = 'daily',
  weekly = 'weekly'
}

export function SummaryEmailNotification({ orgId }: Props) {
  const { user } = useAuth();
  const dispatchBanner = useBannerDispatch();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showInlineMessage, setShowInlineMessage] = useState(false);
  const [frequency, setFrequency] = useState<string>(Frequency.weekly);
  const [loading, setLoading] = useState<boolean>(false);

  const [getNotifications] = useLazyQuery(NotificationsDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    setLoading(true);
    Promise.all(
      Object.keys(Frequency).map(async f => {
        const { data } = await getNotifications({
          variables: {
            scope: orgId,
            event: `digest.${f}_sync_summary`
          }
        });
        if (
          data?.notificationRecipients?.find(
            r => r.event === `digest.${f}_sync_summary` && r.recipientId === user?.email
          )
        ) {
          setFrequency(f);
          setIsChecked(true);
        }
      })
    ).then(() => {
      setLoading(false);
    });
  }, []);

  const [updateUserNotifications, { loading: mutationLoading }] = useMutation(
    UpdateUserEmailNotificationDocument,
    {
      onCompleted: data => {
        setShowInlineMessage(true);
        if (!data.updateUserEmailNotification) {
          setIsChecked(false);
          return;
        }
        Object.keys(Frequency).forEach(f => {
          if (data.updateUserEmailNotification.event === `digest.${f}_sync_summary`) {
            setFrequency(f);
            setIsChecked(true);
          }
        });
      },
      onError: error => {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
        setIsChecked(prev => !prev);
      }
    }
  );

  function handleToggle(checked: boolean, f?: Frequency) {
    setIsChecked(checked);
    if (checked && f) {
      setFrequency(f);
    }
    void updateUserNotifications({
      variables: {
        scope: orgId,
        event: `digest.${f ?? frequency}_sync_summary`,
        enabled: checked
      }
    });
  }

  function clearInline() {
    setShowInlineMessage(false);
  }

  return (
    <div className="flex items-center space-x-2">
      <MySwitch
        loading={loading}
        disabled={mutationLoading}
        checked={isChecked}
        onChange={handleToggle}
      />
      <Label className="block text-sm font-medium">Subscribe to</Label>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={!isChecked} asChild>
          <button
            className={cn(
              'flex space-x-1 rounded bg-gray-200 py-1 pl-2 pr-1 hover:bg-gray-300',
              !isChecked && 'text-gray-500 hover:bg-gray-200'
            )}
          >
            {frequency}
            <Icon className={cn('text-gray-500')} name="SelectSingle" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="start">
          {Object.values(Frequency).map(f => (
            <DropdownMenuItem
              className={frequency === f && 'bg-blue-100'}
              key={f}
              onClick={() => handleToggle(true, f)}
            >
              {f}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <Label className="block text-sm font-medium">sync summary email</Label>
      <InlineMessage show={showInlineMessage} autoDismiss={clearInline} text="Saved" />
    </div>
  );
}
