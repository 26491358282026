import { useController, useFormContext } from 'react-hook-form';

import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { MyCombobox } from '~/components';
import {
  IdentityFunction,
  ModelFieldFragment,
  SupportedIdentityFunction
} from '~/generated/graphql';
import {
  Selectable,
  SyncConfigFormValues,
  formatOptionLabelCapitalize,
  toSelectable
} from '~/utils';

interface Props {
  source: ModelFieldFragment;
  identityFunctions: SupportedIdentityFunction[];
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MappingIdentityFunction = ({ identityFunctions, source, setIsDirty }: Props) => {
  const [showIdFunctionSelect, setShowIdFunctionSelect] = useState(false);
  const { control } = useFormContext<SyncConfigFormValues>();

  const { field } = useController({ control, name: 'identity.function' });

  const [selected, setSelected] = useState<Selectable>();

  const handleChange = (option: Selectable | null) => {
    if (!option) {
      return;
    }
    field.onChange(option.value);
    setShowIdFunctionSelect(false);
    setIsDirty(true);
    setSelected(option);
  };

  const options = useMemo(() => identityFunctions.map(toSelectable), [source, identityFunctions]);

  useEffect(() => {
    const value =
      identityFunctions?.find(fn => fn.id === field.value) ??
      identityFunctions?.find(fn => fn.id === IdentityFunction.Equality) ??
      identityFunctions[0];

    if (!selected) {
      setSelected(toSelectable(value));
      return;
    }

    handleChange(toSelectable(value));
  }, [source]);

  return (
    <div className="col-span-4 justify-self-center">
      {showIdFunctionSelect ? (
        <MyCombobox
          className="w-32"
          options={options}
          value={selected}
          onChange={handleChange}
          formatOptionLabel={formatOptionLabelCapitalize}
        />
      ) : (
        <p className="text-xs text-gray-600">
          Identity function:
          <button
            onClick={() => setShowIdFunctionSelect(true)}
            className="ml-1 text-blue-500 underline hover:no-underline"
          >
            {capitalize(selected?.label ?? 'equals')}
          </button>
        </p>
      )}
    </div>
  );
};

if (import.meta.env.MODE === 'development') {
  MappingIdentityFunction.displayName = 'MappingIdentityFunction';
}
