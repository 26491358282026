import { useSubscription } from '@apollo/client';
import cx from 'clsx';

import {
  BulkSyncExecutionFragment,
  BulkSyncPeekDocument,
  BulkSyncStatusDocument
} from '../../../generated/graphql';
import { useBulkSyncState } from '../components';

export function BulkStatusSub(props: { execution: BulkSyncExecutionFragment | null }) {
  const bulkSync = useBulkSyncState();
  useSubscription(BulkSyncStatusDocument, {
    skip: !bulkSync.id,
    variables: { syncId: bulkSync.id },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const bulkSyncStatus = subscriptionData.data?.bulkSyncStatus;
      const compareOld = cx(
        props.execution?.status,
        bulkSync.nextExecutionTime,
        props.execution?.startedAt,
        props.execution?.completedAt,
        props.execution?.recordCount,
        bulkSync.activeExecutions
      );
      const compareNew = cx(
        bulkSyncStatus?.execution?.status,
        bulkSyncStatus?.nextExecutionTime,
        bulkSyncStatus?.execution?.startedAt,
        bulkSyncStatus?.execution?.completedAt,
        bulkSyncStatus?.execution?.recordCount,
        bulkSyncStatus?.activeExecutions
      );
      if (!bulkSyncStatus || !bulkSyncStatus.execution || compareOld === compareNew) {
        return;
      }
      client.cache.writeQuery({
        query: BulkSyncPeekDocument,
        variables: { id: bulkSync.id },
        data: {
          bulkSync: {
            ...bulkSync,
            execution: bulkSyncStatus.execution,
            activeExecutions: bulkSyncStatus.activeExecutions,
            nextExecutionTime: bulkSyncStatus.nextExecutionTime || bulkSync.nextExecutionTime
          }
        }
      });
    }
  });

  return null;
}
