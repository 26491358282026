/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  ConfirmationKey: { input: any; output: any; }
  Email: { input: any; output: any; }
  Event: { input: any; output: any; }
  FormJSONSchema: { input: any; output: any; }
  JSONPath: { input: any; output: any; }
  JSONSchema: { input: any; output: any; }
  Map: { input: any; output: any; }
  Time: { input: any; output: any; }
  UISchema: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Value: { input: any; output: any; }
  WarningID: { input: any; output: any; }
};

export type ApiConfiguration = {
  __typename?: 'APIConfiguration';
  body: Scalars['String']['output'];
  headers: Array<RequestParameter>;
  method: HttpMethod;
  pagination: ApiPagination;
  parameters: Array<RequestParameter>;
  path: Scalars['String']['output'];
  recordPath: Scalars['JSONPath']['output'];
  sampleResponse?: Maybe<Scalars['String']['output']>;
};

export type ApiPagination = NextPagePagination | OffsetPagination | SequentialPagePagination | TokenPagination | Unconfigured;

export type ApiParameter = {
  __typename?: 'APIParameter';
  location: ParameterLocation;
  name: Scalars['String']['output'];
};

export enum Action {
  ApplyPolicy = 'apply_policy',
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
  Export = 'export',
  ModifyPolicy = 'modify_policy',
  Query = 'query',
  SyncTo = 'sync_to',
  Trigger = 'trigger'
}

export type ActivityEvent = ArrayObj | BoolObj | FloatObj | IntObj | PairObj | RelationshipChangeEvent | StringObj | ValueLabelEvent;

export type ActivityLog = {
  __typename?: 'ActivityLog';
  activityType: Scalars['String']['output'];
  changeID: Scalars['UUID']['output'];
  context?: Maybe<Scalars['Map']['output']>;
  eventSchema: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  new?: Maybe<ActivityEvent>;
  objectID: Scalars['UUID']['output'];
  objectType: Scalars['String']['output'];
  old?: Maybe<ActivityEvent>;
  performedAt: Scalars['Time']['output'];
  performedBy: Actor;
};

export type ActivityLogConnection = {
  __typename?: 'ActivityLogConnection';
  edges?: Maybe<Array<ActivityLogEdge>>;
  pageInfo: PageInfo;
};

export type ActivityLogEdge = {
  __typename?: 'ActivityLogEdge';
  cursor: Scalars['String']['output'];
  node: ActivityLog;
};

export type Actor = {
  __typename?: 'Actor';
  actorType: ActorType;
  displayName: Scalars['String']['output'];
  id?: Maybe<Scalars['UUID']['output']>;
};

export enum ActorType {
  Organization = 'organization',
  Partner = 'partner',
  Support = 'support',
  System = 'system',
  User = 'user'
}

export type AffinityConfiguration = {
  __typename?: 'AffinityConfiguration';
  object: Scalars['String']['output'];
};

export type AffinityTargetConfiguration = {
  __typename?: 'AffinityTargetConfiguration';
  searchDomains?: Maybe<Scalars['Boolean']['output']>;
};

export type AirtableConfiguration = {
  __typename?: 'AirtableConfiguration';
  baseobj: ValueLabel;
  tableobj: ValueLabel;
  viewobj?: Maybe<ValueLabel>;
};

export type AirtableDestinationConfiguration = {
  __typename?: 'AirtableDestinationConfiguration';
  base?: Maybe<Scalars['String']['output']>;
};

export type ApiKeyRecord = {
  __typename?: 'ApiKeyRecord';
  apiKey: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  id: Scalars['UUID']['output'];
};

export type ApolloEnrichmentConfiguration = {
  __typename?: 'ApolloEnrichmentConfiguration';
  object: Scalars['String']['output'];
};

export type ArrayObj = {
  __typename?: 'ArrayObj';
  arr?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AscendConfiguration = {
  __typename?: 'AscendConfiguration';
  streamID: Scalars['String']['output'];
};

export type AthenaConfiguration = {
  __typename?: 'AthenaConfiguration';
  catalog: Scalars['String']['output'];
  database: Scalars['String']['output'];
  query?: Maybe<Scalars['String']['output']>;
  table: Scalars['String']['output'];
  trackingColumns: Array<ModelField>;
};

export type AttioConfiguration = {
  __typename?: 'AttioConfiguration';
  list?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
};

export type BigQueryConfiguration = {
  __typename?: 'BigQueryConfiguration';
  dataset?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  table: Scalars['String']['output'];
  trackingColumns: Array<ModelField>;
};

export type BigQueryDestinationConfiguration = {
  __typename?: 'BigQueryDestinationConfiguration';
  advanced: WarehouseDestinationAdvancedConfiguration;
  dataset?: Maybe<Scalars['String']['output']>;
  mirrorSchemas?: Maybe<Scalars['Boolean']['output']>;
};

export type BlobDestinationConfiguration = {
  __typename?: 'BlobDestinationConfiguration';
  format: OutputFormat;
  subfolder: Scalars['String']['output'];
  writeIncremental: Scalars['Boolean']['output'];
};

export enum BlobModelFrom {
  MultiFileArchive = 'multi_file_archive',
  SingleFile = 'single_file'
}

export type BlobTargetConfiguration = {
  __typename?: 'BlobTargetConfiguration';
  format: OutputFormat;
};

export type BlobstoreConfiguration = {
  __typename?: 'BlobstoreConfiguration';
  fileFormat?: Maybe<FileType>;
  key?: Maybe<Scalars['String']['output']>;
  modelFrom: BlobModelFrom;
  skipLines?: Maybe<Scalars['Int']['output']>;
  subfolder?: Maybe<Scalars['String']['output']>;
};

export type BoolObj = {
  __typename?: 'BoolObj';
  val: Scalars['Boolean']['output'];
};

export type BulkDestinationConfiguration = AirtableDestinationConfiguration | BigQueryDestinationConfiguration | BlobDestinationConfiguration | DatabaseDestinationConfiguration | DatabricksDestinationConfiguration | GleanDestinationConfiguration | MySqlDestinationConfiguration | NoBulkConfiguration | WarehouseDestinationConfiguration;

export type BulkDestinationConnection = {
  __typename?: 'BulkDestinationConnection';
  configurationForm?: Maybe<FormDefinition>;
  configurationSchema?: Maybe<Scalars['JSONSchema']['output']>;
  connection: Connection;
  defaultConfiguration: BulkDestinationConfiguration;
  id: Scalars['UUID']['output'];
  properties: BulkDestinationProperties;
  supportedModes: Array<SupportedBulkMode>;
};

export type BulkDestinationProperties = {
  __typename?: 'BulkDestinationProperties';
  sanitizeConfigurationInput: Scalars['Boolean']['output'];
  supportsIncremental: Scalars['Boolean']['output'];
  supportsPartitions: Scalars['Boolean']['output'];
  vocabulary: BulkDestinationVocabulary;
};

export type BulkDestinationVocabulary = {
  __typename?: 'BulkDestinationVocabulary';
  partitionKey: Scalars['String']['output'];
};

export enum BulkExecutionStatus {
  Canceled = 'canceled',
  Canceling = 'canceling',
  Completed = 'completed',
  Created = 'created',
  Errors = 'errors',
  Exporting = 'exporting',
  Failed = 'failed',
  Processing = 'processing',
  Running = 'running',
  Scheduled = 'scheduled'
}

export enum BulkExecutionType {
  Api = 'api',
  Automatic = 'automatic',
  FullResync = 'full_resync',
  Manual = 'manual',
  PartialResync = 'partial_resync',
  PartialSync = 'partial_sync',
  Scheduled = 'scheduled',
  Test = 'test'
}

export type BulkExecutionsCondition = {
  betweenTime?: InputMaybe<ExecutionsBetweenTimesFilter>;
  schema?: InputMaybe<BulkExecutionsSchemaEqualFilter>;
  status?: InputMaybe<BulkExecutionsStatusEqualsFilter>;
  time?: InputMaybe<ExecutionsInTheLastFilter>;
};

export type BulkExecutionsSchemaEqualFilter = {
  comparator: BulkExecutionsSchemaEqualFilterFunc;
  schemaID: Scalars['String']['input'];
};

export enum BulkExecutionsSchemaEqualFilterFunc {
  Equality = 'Equality',
  Inequality = 'Inequality'
}

export enum BulkExecutionsSort {
  CreatedAt = 'CreatedAt',
  RecordCount = 'RecordCount',
  Runtime = 'Runtime'
}

export type BulkExecutionsStatusEqualsFilter = {
  status: BulkExecutionStatus;
};

export type BulkFieldSelection = {
  id: Scalars['ID']['input'];
  obfuscate: Scalars['Boolean']['input'];
};

export type BulkFilter = {
  __typename?: 'BulkFilter';
  fieldID: Scalars['ID']['output'];
  function: FilterFunction;
  value?: Maybe<Scalars['Value']['output']>;
};

export type BulkFilterInput = {
  fieldID: Scalars['ID']['input'];
  function: FilterFunction;
  value?: InputMaybe<Scalars['Value']['input']>;
};

export type BulkNamespaceSelection = {
  id?: InputMaybe<Scalars['ID']['input']>;
  schemas: Array<BulkSchemaSelection>;
};

export type BulkSchedule = {
  frequency: Frequency;
};

export type BulkScheduleUpdate = {
  dayOfMonth?: InputMaybe<Scalars['String']['input']>;
  dayOfWeek?: InputMaybe<Scalars['String']['input']>;
  frequency: Frequency;
  hour?: InputMaybe<Scalars['String']['input']>;
  minute?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  multi?: InputMaybe<MultiScheduleUpdate>;
};

export type BulkSchemaSelection = {
  fields: Array<BulkFieldSelection>;
  filters?: InputMaybe<Array<BulkFilterInput>>;
  id: Scalars['ID']['input'];
  partitionKey?: InputMaybe<Scalars['String']['input']>;
  selectionState: SelectionState;
  trackingField?: InputMaybe<Scalars['String']['input']>;
};

export type BulkSelectedField = {
  __typename?: 'BulkSelectedField';
  id: Scalars['ID']['output'];
  obfuscate: Scalars['Boolean']['output'];
};

export type BulkSelectedNamespace = {
  __typename?: 'BulkSelectedNamespace';
  id?: Maybe<Scalars['ID']['output']>;
  schemas: Array<BulkSelectedSchema>;
};

export type BulkSelectedSchema = {
  __typename?: 'BulkSelectedSchema';
  destinationOutputName?: Maybe<Scalars['String']['output']>;
  fields: Array<BulkSelectedField>;
  filters?: Maybe<Array<BulkFilter>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partitionKey?: Maybe<Scalars['String']['output']>;
  selectionState: SelectionState;
  trackingField?: Maybe<Scalars['String']['output']>;
};

export type BulkSourceConfiguration = GongSourceConfiguration | NoConfiguration | PostgresqlSourceConfiguration | StripeSourceConfiguration;

export type BulkSourceConnection = {
  __typename?: 'BulkSourceConnection';
  configurationForm?: Maybe<FormDefinition>;
  connection: Connection;
  id: Scalars['UUID']['output'];
  namespaceLabel?: Maybe<Term>;
  namespaces: Array<Namespace>;
  properties: BulkSourceProperties;
  schemaLabel: Term;
};


export type BulkSourceConnectionNamespacesArgs = {
  filters?: InputMaybe<SchemaFilters>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type BulkSourceProperties = {
  __typename?: 'BulkSourceProperties';
  hideSystemName: Scalars['Boolean']['output'];
  supportsIncremental: Scalars['Boolean']['output'];
  supportsNamespaces: Scalars['Boolean']['output'];
  supportsTrackingFields: Scalars['Boolean']['output'];
};

export type BulkSync = {
  __typename?: 'BulkSync';
  acl: Array<Action>;
  active: Scalars['Boolean']['output'];
  activeExecutions: Array<BulkSyncExecution>;
  destination?: Maybe<BulkDestinationConnection>;
  destinationConfiguration?: Maybe<BulkDestinationConfiguration>;
  disableRecordTimestamps?: Maybe<Scalars['Boolean']['output']>;
  execution?: Maybe<BulkSyncExecution>;
  features: Array<Scalars['String']['output']>;
  fieldDiscovery?: Maybe<Discover>;
  id: Scalars['UUID']['output'];
  mode: SyncMode;
  name: Scalars['String']['output'];
  namespaces: Array<BulkSelectedNamespace>;
  nextExecutionTime?: Maybe<Scalars['Time']['output']>;
  objectExecutions: Array<Maybe<BulkSyncSchemaExecution>>;
  schedule?: Maybe<BulkSchedule>;
  source?: Maybe<BulkSourceConnection>;
  sourceConfiguration?: Maybe<BulkSourceConfiguration>;
  tableDiscovery?: Maybe<Discover>;
  tags: Array<PermissionTag>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
  updatedBy?: Maybe<UpdatedBy>;
};


export type BulkSyncExecutionArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type BulkSyncExecution = {
  __typename?: 'BulkSyncExecution';
  completedAt?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  errorCount?: Maybe<Scalars['Int']['output']>;
  formattedDuration: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  recordCount: Scalars['Int']['output'];
  schemas: Array<BulkSyncSchemaExecution>;
  startedAt?: Maybe<Scalars['Time']['output']>;
  status: BulkExecutionStatus;
  statusMessage?: Maybe<Scalars['String']['output']>;
  syncId: Scalars['UUID']['output'];
  type: BulkExecutionType;
};

export type BulkSyncExecutionConnection = {
  __typename?: 'BulkSyncExecutionConnection';
  edges?: Maybe<Array<BulkSyncExecutionEdge>>;
  pageInfo: BulkSyncPageInfo;
};

export type BulkSyncExecutionEdge = {
  __typename?: 'BulkSyncExecutionEdge';
  cursor: Scalars['String']['output'];
  node: BulkSyncExecution;
};

export type BulkSyncPageInfo = {
  __typename?: 'BulkSyncPageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type BulkSyncSchemaExecution = {
  __typename?: 'BulkSyncSchemaExecution';
  completedAt?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  errorCount?: Maybe<Scalars['Int']['output']>;
  executionId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  recordCount: Scalars['Int']['output'];
  schema: BulkSelectedSchema;
  slowMode: Scalars['Boolean']['output'];
  startedAt?: Maybe<Scalars['Time']['output']>;
  status: SchemaExecutionStatus;
  statusMessage: Scalars['String']['output'];
};

export type BulkSyncStatus = {
  __typename?: 'BulkSyncStatus';
  activeExecutions: Array<BulkSyncExecution>;
  execution?: Maybe<BulkSyncExecution>;
  nextExecutionTime?: Maybe<Scalars['Time']['output']>;
};

export type BulkSyncSuccessOrConfirmation = BulkSync | ConfirmationRequest | SyncErrorResponse;

export type BulkSyncUpdate = {
  destConnectionId: Scalars['UUID']['input'];
  destinationConfiguration?: InputMaybe<Scalars['Map']['input']>;
  disableRecordTimestamps?: InputMaybe<Scalars['Boolean']['input']>;
  fieldDiscovery?: InputMaybe<Discover>;
  mode?: InputMaybe<SyncMode>;
  name: Scalars['String']['input'];
  namespaces: Array<BulkNamespaceSelection>;
  schedule?: InputMaybe<BulkScheduleUpdate>;
  sourceConfiguration?: InputMaybe<Scalars['Map']['input']>;
  sourceConnectionId: Scalars['UUID']['input'];
  tableDiscovery?: InputMaybe<Discover>;
};

export type CsvConfiguration = {
  __typename?: 'CSVConfiguration';
  delim: Scalars['String']['output'];
  headers: Array<RequestParameter>;
  parameters: Array<RequestParameter>;
  path: Scalars['String']['output'];
};

export type Chargebee = {
  __typename?: 'Chargebee';
  acl: Array<Action>;
  id: Scalars['String']['output'];
  tags: Array<PermissionTag>;
};

export type ChargebeeConfiguration = {
  __typename?: 'ChargebeeConfiguration';
  streamID: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type ChargebeePortalPacket = {
  __typename?: 'ChargebeePortalPacket';
  accessUrl: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['String']['output'];
  expiresAt: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  object: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CompletionResponse = {
  __typename?: 'CompletionResponse';
  allowCreation: Scalars['Boolean']['output'];
  values: Array<CompletionValue>;
};

export type CompletionValue = {
  __typename?: 'CompletionValue';
  label?: Maybe<Scalars['String']['output']>;
  value: Scalars['Any']['output'];
};

export enum ConcurrencyMode {
  Parallel = 'Parallel',
  Serial = 'Serial'
}

export type Configuration = {
  __typename?: 'Configuration';
  values?: Maybe<Scalars['Map']['output']>;
};

export type ConfirmationRequest = {
  __typename?: 'ConfirmationRequest';
  confirm: Scalars['ConfirmationKey']['output'];
  message: Scalars['String']['output'];
};

export type Connection = {
  __typename?: 'Connection';
  acl: Array<Action>;
  authURL?: Maybe<Scalars['String']['output']>;
  authorizationURL: Scalars['String']['output'];
  authorizedAt?: Maybe<Scalars['Time']['output']>;
  configuration: Scalars['Map']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  createdBy?: Maybe<User>;
  fieldTypes: Array<RemoteFieldType>;
  health: Health;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  saved: Scalars['Boolean']['output'];
  schemaRefreshStatus: SchemaRefreshStatus;
  stats: ConnectionStats;
  tags: Array<PermissionTag>;
  type: ConnectionType;
  updatedAt?: Maybe<Scalars['Time']['output']>;
};


export type ConnectionAuthorizationUrlArgs = {
  update?: InputMaybe<ConnectionUpdate>;
};

export type ConnectionDeletedSuccessResponse = {
  __typename?: 'ConnectionDeletedSuccessResponse';
  id: Scalars['UUID']['output'];
};

export type ConnectionErrorResponse = {
  __typename?: 'ConnectionErrorResponse';
  error: Scalars['String']['output'];
  usedBy: Array<ConnectionUsedBy>;
};

export type ConnectionPeek = {
  __typename?: 'ConnectionPeek';
  ConnectionID: Scalars['UUID']['output'];
  ConnectionName: Scalars['String']['output'];
  ConnectionType: Scalars['String']['output'];
};

export type ConnectionStats = {
  __typename?: 'ConnectionStats';
  callsLast24h?: Maybe<Scalars['Int']['output']>;
};

export type ConnectionTerminology = {
  __typename?: 'ConnectionTerminology';
  fieldLabel: Term;
  schemaLabel: Term;
};

export type ConnectionType = {
  __typename?: 'ConnectionType';
  configurationForm?: Maybe<FormDefinition>;
  configurationSchema?: Maybe<Scalars['JSONSchema']['output']>;
  destinationDataArchitecture?: Maybe<Scalars['JSONSchema']['output']>;
  enabled: Scalars['Boolean']['output'];
  envConfig: Scalars['Map']['output'];
  id: Scalars['String']['output'];
  initialConfiguration?: Maybe<Scalars['Map']['output']>;
  name: Scalars['String']['output'];
  operations: Array<Operation>;
  terminology: ConnectionTerminology;
  useOAuth: Scalars['Boolean']['output'];
};

export type ConnectionUpdate = {
  configuration: Scalars['Map']['input'];
  files: Array<FileUpload>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type ConnectionUsedBy = BulkSync | Fieldset | IntegrationSchedule | Sync;

export type Continuation = {
  __typename?: 'Continuation';
  id: Scalars['ID']['output'];
  interval: Scalars['Int']['output'];
};

export type ContinuousSchedule = BulkSchedule & Schedule & {
  __typename?: 'ContinuousSchedule';
  frequency: Frequency;
};

export type CosmosDbConfiguration = {
  __typename?: 'CosmosDBConfiguration';
  collection: Scalars['String']['output'];
  database: Scalars['String']['output'];
  filters: Array<ModelFilter>;
  query?: Maybe<Scalars['String']['output']>;
};

export type CustomSchedule = BulkSchedule & Schedule & {
  __typename?: 'CustomSchedule';
  dayOfMonth: Scalars['String']['output'];
  dayOfWeek: Scalars['String']['output'];
  frequency: Frequency;
  hour: Scalars['String']['output'];
  minute: Scalars['String']['output'];
  month: Scalars['String']['output'];
};

export type DbtCloudSchedule = Schedule & {
  __typename?: 'DBTCloudSchedule';
  connectionId: Scalars['UUID']['output'];
  frequency: Frequency;
  jobId: Scalars['Int']['output'];
};

export type DailySchedule = BulkSchedule & Schedule & {
  __typename?: 'DailySchedule';
  frequency: Frequency;
  hour: Scalars['String']['output'];
  minute: Scalars['String']['output'];
};

export type DataAccessConfiguration = {
  __typename?: 'DataAccessConfiguration';
  object: Scalars['String']['output'];
};

export type DataArchitectureResponse = {
  __typename?: 'DataArchitectureResponse';
  schema: Scalars['JSONSchema']['output'];
};

export type DataArchitectureResponseOrContinuation = Continuation | DataArchitectureResponse;

export type DatabaseDestinationAdvancedConfiguration = {
  __typename?: 'DatabaseDestinationAdvancedConfiguration';
  tablePrefix: Scalars['String']['output'];
  truncateExisting: Scalars['Boolean']['output'];
};

export type DatabaseDestinationConfiguration = {
  __typename?: 'DatabaseDestinationConfiguration';
  advanced: DatabaseDestinationAdvancedConfiguration;
  mirrorSchemas?: Maybe<Scalars['Boolean']['output']>;
  schema?: Maybe<Scalars['String']['output']>;
};

export type DatabaseSchema = {
  __typename?: 'DatabaseSchema';
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  tables: Array<Table>;
};

export type DatabaseTargetConfiguration = {
  __typename?: 'DatabaseTargetConfiguration';
  preserveTableOnResync: Scalars['Boolean']['output'];
};

export type DatabricksConfiguration = {
  __typename?: 'DatabricksConfiguration';
  catalog: Scalars['String']['output'];
  query?: Maybe<Scalars['String']['output']>;
  schema: Scalars['String']['output'];
  table: Scalars['String']['output'];
  trackingColumns: Array<ModelField>;
};

export type DatabricksDestinationConfiguration = {
  __typename?: 'DatabricksDestinationConfiguration';
  advanced: WarehouseDestinationAdvancedConfiguration;
  catalog: Scalars['String']['output'];
  externalLocationName?: Maybe<Scalars['String']['output']>;
  mirrorSchemas?: Maybe<Scalars['Boolean']['output']>;
  schema?: Maybe<Scalars['String']['output']>;
};

export type DataliteConfiguration = {
  __typename?: 'DataliteConfiguration';
  query?: Maybe<Scalars['String']['output']>;
  schemas: Array<DataliteSchemaConfiguration>;
};

export type DataliteSchemaConfiguration = {
  __typename?: 'DataliteSchemaConfiguration';
  alias: Scalars['String']['output'];
  connectionID: Scalars['ID']['output'];
  connectionName: Scalars['String']['output'];
  connectionType: Scalars['String']['output'];
  schemaID: Scalars['String']['output'];
};

export type DataliteSchemaMetadata = {
  __typename?: 'DataliteSchemaMetadata';
  alias: Scalars['String']['output'];
  connectionID: Scalars['ID']['output'];
  connectionName: Scalars['String']['output'];
  connectionType: Scalars['String']['output'];
  initialized: Scalars['Boolean']['output'];
  lastUpdated?: Maybe<Scalars['Time']['output']>;
  recordCount: Scalars['Int']['output'];
  schemaID: Scalars['String']['output'];
};

export type DeleteConnectionResponse = ConnectionDeletedSuccessResponse | ConnectionErrorResponse;

export type DeleteLabelResponse = {
  __typename?: 'DeleteLabelResponse';
  ok: Scalars['Boolean']['output'];
};

export type DeleteSyncResponse = SyncDeletedSuccessResponse | SyncErrorResponse;

export type DelightedConfiguration = {
  __typename?: 'DelightedConfiguration';
  object: Scalars['String']['output'];
};

export type DialpadConfiguration = {
  __typename?: 'DialpadConfiguration';
  object: Scalars['String']['output'];
};

export enum Discover {
  All = 'all',
  None = 'none',
  OnlyIncremental = 'onlyIncremental',
  OnlyNonIncremental = 'onlyNonIncremental'
}

export type DynamoDbConfiguration = {
  __typename?: 'DynamoDBConfiguration';
  consistent: Scalars['Boolean']['output'];
  index?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  table: Scalars['String']['output'];
};

export type Enrichment = {
  __typename?: 'Enrichment';
  mappings: Scalars['Map']['output'];
  provider: Fieldset;
};

export type EnrichmentUpdate = {
  configuation: Scalars['Map']['input'];
  connectionId: Scalars['UUID']['input'];
  fields: Array<FieldUpdate>;
  id: Scalars['UUID']['input'];
  mappings: Scalars['Map']['input'];
};

export type EventConfigurationUpdate = {
  connId: Scalars['UUID']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type EventConnectionResponse = {
  __typename?: 'EventConnectionResponse';
  connection?: Maybe<Connection>;
};

export enum ExecutionCountField {
  DeleteCount = 'deleteCount',
  ErrorCount = 'errorCount',
  InsertCount = 'insertCount',
  RecordCount = 'recordCount',
  UpdateCount = 'updateCount',
  WarningCount = 'warningCount'
}

export enum ExecutionStatus {
  Canceled = 'canceled',
  Canceling = 'canceling',
  Completed = 'completed',
  Created = 'created',
  Failed = 'failed',
  Interrupted = 'interrupted',
  Processing = 'processing',
  Queued = 'queued',
  Running = 'running',
  Scheduled = 'scheduled'
}

export enum ExecutionTrigger {
  Api = 'api',
  Automatic = 'automatic',
  Dbtcloud = 'dbtcloud',
  Manual = 'manual',
  Scheduled = 'scheduled',
  Test = 'test'
}

export type ExecutionsBetweenTimesFilter = {
  from: Scalars['Time']['input'];
  to: Scalars['Time']['input'];
};

export type ExecutionsCondition = {
  betweenTime?: InputMaybe<ExecutionsBetweenTimesFilter>;
  counts?: InputMaybe<Array<ExecutionsCountFilter>>;
  status?: InputMaybe<ExecutionsStatusEqualsFilter>;
  time?: InputMaybe<ExecutionsInTheLastFilter>;
  trigger?: InputMaybe<ExecutionsTriggerEqualsFilter>;
};

export type ExecutionsCountFilter = {
  comparator: NumericComparator;
  field: ExecutionCountField;
  value: Scalars['Int']['input'];
};

export type ExecutionsInTheLastFilter = {
  seconds: Scalars['Int']['input'];
};

export type ExecutionsStatusEqualsFilter = {
  status: ExecutionStatus;
};

export type ExecutionsTriggerEqualsFilter = {
  trigger: ExecutionTrigger;
};

export type ExploreStatus = {
  __typename?: 'ExploreStatus';
  available: Scalars['Boolean']['output'];
  fieldsetID: Scalars['UUID']['output'];
  formattedLastUpdated: Scalars['String']['output'];
  lastUpdated?: Maybe<Scalars['Time']['output']>;
};

export type ExploreStatusOrPoller = ExploreStatus | Poller;

export type ExportSqlResult = {
  __typename?: 'ExportSqlResult';
  downloadLink: Scalars['String']['output'];
};

export type ExportSqlResultOrContinuation = Continuation | ExportSqlResult;

export type Field = {
  __typename?: 'Field';
  id: Scalars['ID']['output'];
  isCalculated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partitionable: Scalars['Boolean']['output'];
  primaryKey: Scalars['Boolean']['output'];
  slowMode: Scalars['Boolean']['output'];
  slowReason?: Maybe<Scalars['String']['output']>;
  trackable: Scalars['Boolean']['output'];
  type: FieldType;
};

export type FieldFilters = {
  search?: InputMaybe<Scalars['String']['input']>;
  syncType?: InputMaybe<FieldSyncType>;
};

export type FieldMapping = {
  __typename?: 'FieldMapping';
  model?: Maybe<ModelField>;
  newField?: Maybe<Scalars['Boolean']['output']>;
  syncMode?: Maybe<SyncMode>;
  target?: Maybe<TargetField>;
};

export enum FieldReferenceType {
  Model = 'Model',
  Target = 'Target'
}

export enum FieldState {
  All = 'All',
  Published = 'Published'
}

export enum FieldSyncType {
  Calculated = 'calculated',
  Incremental = 'incremental',
  Nonincremental = 'nonincremental'
}

export enum FieldType {
  Array = 'array',
  Boolean = 'boolean',
  Datetime = 'datetime',
  Number = 'number',
  Object = 'object',
  String = 'string',
  Unknown = 'unknown'
}

export type FieldUpdate = {
  description: Scalars['String']['input'];
  example?: InputMaybe<Scalars['Any']['input']>;
  id: Scalars['UUID']['input'];
  label: Scalars['String']['input'];
  published: Scalars['Boolean']['input'];
  sourceName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FieldType>;
  userAdded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Fieldset = {
  __typename?: 'Fieldset';
  acl: Array<Action>;
  configuration?: Maybe<FieldsetConfiguration>;
  configurationForm?: Maybe<FormDefinition>;
  configurationSchema?: Maybe<Scalars['JSONSchema']['output']>;
  connection: Connection;
  createdBy: Scalars['UUID']['output'];
  enrichments: Array<Enrichment>;
  exploreStatus?: Maybe<ExploreStatus>;
  fields: Array<ModelField>;
  fieldsetType: FieldsetType;
  id: Scalars['UUID']['output'];
  labels: Array<Label>;
  name: Scalars['String']['output'];
  primaryKey?: Maybe<ModelField>;
  properties: FieldsetProperties;
  realName: Scalars['String']['output'];
  relatedFrom: Array<Relationship>;
  relatedTo: Array<Relationship>;
  tags: Array<PermissionTag>;
  trackableFields: Array<ModelField>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
  updatedBy?: Maybe<UpdatedBy>;
};


export type FieldsetConfigurationFormArgs = {
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
};


export type FieldsetConfigurationSchemaArgs = {
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FieldsetConfiguration = ApiConfiguration | AffinityConfiguration | AirtableConfiguration | ApolloEnrichmentConfiguration | AscendConfiguration | AthenaConfiguration | AttioConfiguration | BigQueryConfiguration | BlobstoreConfiguration | CsvConfiguration | ChargebeeConfiguration | CosmosDbConfiguration | DataAccessConfiguration | DatabricksConfiguration | DataliteConfiguration | DelightedConfiguration | DialpadConfiguration | DynamoDbConfiguration | FrontConfiguration | GSheetsConfiguration | GoogleWorkspaceConfiguration | HarmonicConfiguration | HarmonicEnrichmentConfiguration | HubspotConfiguration | IntercomConfiguration | IroncladConfiguration | KlaviyoConfiguration | KustomerConfiguration | MailerCheckEnrichmentConfiguration | MarketoConfiguration | MongoDbConfiguration | NetsuiteConfiguration | OutreachConfiguration | PipedriveConfiguration | PredictLeadsEnrichmentConfiguration | QuickBooksConfiguration | ReplyioConfiguration | SqlConfiguration | SalesforceConfiguration | ScamalyticsConfiguration | SeamAIConfiguration | ShipbobConfiguration | ShopifyConfiguration | StatsigConfiguration | StripeConfiguration | SurvicateConfiguration | Unconfigured | UserVoiceConfiguration | VanillaConfiguration | WarehouseConfiguration | ZendeskConfiguration | ZoomInfoConfiguration | ZoomInfoEnrichmentConfiguration;

export type FieldsetOrContinuation = Continuation | Fieldset;

export type FieldsetProperties = {
  __typename?: 'FieldsetProperties';
  enrichmentDisabled: Scalars['Boolean']['output'];
  trackingFieldSelection: Scalars['Boolean']['output'];
  uniqueIdSelection: Scalars['Boolean']['output'];
  userTypeSelection: Scalars['Boolean']['output'];
  writeinFields: Scalars['Boolean']['output'];
};

export enum FieldsetType {
  Api = 'API',
  Affinity = 'Affinity',
  Airtable = 'Airtable',
  Ascend = 'Ascend',
  Athena = 'Athena',
  Attio = 'Attio',
  Csv = 'CSV',
  Chargebee = 'Chargebee',
  CosmosDb = 'CosmosDB',
  Datalite = 'Datalite',
  Delighted = 'Delighted',
  Dialpad = 'Dialpad',
  Fakedata = 'Fakedata',
  Freshdesk = 'Freshdesk',
  Front = 'Front',
  GSheets = 'GSheets',
  GoogleWorkspace = 'GoogleWorkspace',
  Harmonic = 'Harmonic',
  Hubspot = 'Hubspot',
  Ironclad = 'Ironclad',
  Klaviyo = 'Klaviyo',
  Kustomer = 'Kustomer',
  MailerCheck = 'MailerCheck',
  Marketo = 'Marketo',
  MongoDb = 'MongoDB',
  Netsuite = 'Netsuite',
  Outreach = 'Outreach',
  Pipedrive = 'Pipedrive',
  PredictLeads = 'PredictLeads',
  QuickBooks = 'QuickBooks',
  Replyio = 'Replyio',
  S3 = 'S3',
  Sql = 'SQL',
  Salesforce = 'Salesforce',
  Shipbob = 'Shipbob',
  Shopify = 'Shopify',
  Statsig = 'Statsig',
  Stripe = 'Stripe',
  Survicate = 'Survicate',
  UserVoice = 'UserVoice',
  Vanilla = 'Vanilla',
  Zendesk = 'Zendesk',
  ZoomInfo = 'ZoomInfo'
}

export type FieldsetUpdate = {
  aggregation?: InputMaybe<Scalars['String']['input']>;
  base?: InputMaybe<Scalars['String']['input']>;
  collection?: InputMaybe<Scalars['String']['input']>;
  database?: InputMaybe<Scalars['String']['input']>;
  dataset?: InputMaybe<Scalars['String']['input']>;
  delim?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  list?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sheet?: InputMaybe<Scalars['String']['input']>;
  streamID?: InputMaybe<Scalars['String']['input']>;
  survey?: InputMaybe<Scalars['String']['input']>;
  table?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
  trackingColumns?: InputMaybe<Array<Scalars['ID']['input']>>;
  version?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<Scalars['String']['input']>;
};

export type FieldsetUpdateResult = {
  __typename?: 'FieldsetUpdateResult';
  fieldset: Fieldset;
  model: Model;
};

export enum FileType {
  Csv = 'csv',
  Json = 'json',
  Parquet = 'parquet'
}

export type FileUpload = {
  key: Scalars['String']['input'];
  upload: Scalars['Upload']['input'];
};

export type Filter = {
  __typename?: 'Filter';
  field?: Maybe<FilterField>;
  function?: Maybe<FilterFunction>;
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['Value']['output']>;
};

export type FilterField = ModelField | TargetField;

export enum FilterFunction {
  ArrayContains = 'ArrayContains',
  ArrayDoesNotContain = 'ArrayDoesNotContain',
  Between = 'Between',
  Equality = 'Equality',
  False = 'False',
  GreaterThan = 'GreaterThan',
  GreaterThanEqual = 'GreaterThanEqual',
  InTheLast = 'InTheLast',
  Inequality = 'Inequality',
  IsNotNull = 'IsNotNull',
  IsNull = 'IsNull',
  LessThan = 'LessThan',
  LessThanEqual = 'LessThanEqual',
  OnOrAfter = 'OnOrAfter',
  OnOrBefore = 'OnOrBefore',
  StringContains = 'StringContains',
  StringDoesNotContain = 'StringDoesNotContain',
  StringDoesNotEndWith = 'StringDoesNotEndWith',
  StringEndsWith = 'StringEndsWith',
  StringLike = 'StringLike',
  StringNotLike = 'StringNotLike',
  StringNotOneOf = 'StringNotOneOf',
  StringOneOf = 'StringOneOf',
  True = 'True'
}

export type FloatObj = {
  __typename?: 'FloatObj';
  float: Scalars['Float']['output'];
};

export type FormDefinition = {
  __typename?: 'FormDefinition';
  jsonschema: Scalars['FormJSONSchema']['output'];
  uischema?: Maybe<Scalars['UISchema']['output']>;
};

export enum Frequency {
  Builder = 'builder',
  Continuous = 'continuous',
  Custom = 'custom',
  Daily = 'daily',
  Dbtcloud = 'dbtcloud',
  Hourly = 'hourly',
  Manual = 'manual',
  Multi = 'multi',
  Runafter = 'runafter',
  Weekly = 'weekly'
}

export type FrontConfiguration = {
  __typename?: 'FrontConfiguration';
  streamID: Scalars['String']['output'];
};

export type GSheetsConfiguration = {
  __typename?: 'GSheetsConfiguration';
  selectedSheet?: Maybe<SheetSelection>;
  sheet: Scalars['String']['output'];
};

export type GetApiKeyResponse = {
  __typename?: 'GetApiKeyResponse';
  acl: Array<Action>;
  apiKey?: Maybe<ApiKeyRecord>;
};

export type GleanDestinationConfiguration = {
  __typename?: 'GleanDestinationConfiguration';
  datasource?: Maybe<Scalars['String']['output']>;
};

export type GongSourceConfiguration = {
  __typename?: 'GongSourceConfiguration';
  syncIncremental?: Maybe<Scalars['Boolean']['output']>;
};

export type GoogleWorkspaceConfiguration = {
  __typename?: 'GoogleWorkspaceConfiguration';
  streamID: Scalars['String']['output'];
};

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST'
}

export type HarmonicConfiguration = {
  __typename?: 'HarmonicConfiguration';
  entity: Scalars['String']['output'];
  source: HarmonicSource;
  streamID: Scalars['String']['output'];
};

export type HarmonicEnrichmentConfiguration = {
  __typename?: 'HarmonicEnrichmentConfiguration';
  object: Scalars['String']['output'];
};

export enum HarmonicSource {
  Company = 'company',
  CompanyGql = 'companyGql',
  PersonGql = 'personGql',
  SavedSearch = 'savedSearch',
  SavedSearchGql = 'savedSearchGql',
  SavedSearchPeople = 'savedSearchPeople',
  SavedSearchPeopleGql = 'savedSearchPeopleGql',
  WatchlistCompanies = 'watchlistCompanies',
  WatchlistCompaniesGql = 'watchlistCompaniesGql',
  WatchlistPeopleGql = 'watchlistPeopleGql'
}

export type Header = {
  __typename?: 'Header';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type Health = {
  __typename?: 'Health';
  errors: Array<Scalars['String']['output']>;
  lastErrorAt?: Maybe<Scalars['Time']['output']>;
  lastSuccessAt?: Maybe<Scalars['Time']['output']>;
  status: HealthStatus;
};

export enum HealthStatus {
  Error = 'error',
  Healthy = 'healthy'
}

export type HistoryQuery = {
  __typename?: 'HistoryQuery';
  last_executed_at: Scalars['Time']['output'];
  query: Scalars['String']['output'];
};

export type HourlySchedule = BulkSchedule & Schedule & {
  __typename?: 'HourlySchedule';
  frequency: Frequency;
  minute: Scalars['String']['output'];
};

export type HubspotConfiguration = {
  __typename?: 'HubspotConfiguration';
  streamID: Scalars['String']['output'];
};

export type HubspotContactTargetConfiguration = {
  __typename?: 'HubspotContactTargetConfiguration';
  ignoreAdditionalEmails: Scalars['Boolean']['output'];
  writeNullValues: Scalars['Boolean']['output'];
};

export type HubspotTargetConfiguration = {
  __typename?: 'HubspotTargetConfiguration';
  writeNullValues: Scalars['Boolean']['output'];
};

export enum IdentityFunction {
  Equality = 'Equality',
  ISubstring = 'ISubstring',
  OneOf = 'OneOf'
}

export type IdentityMapping = {
  __typename?: 'IdentityMapping';
  function?: Maybe<IdentityFunction>;
  model?: Maybe<ModelField>;
  newField?: Maybe<Scalars['Boolean']['output']>;
  target?: Maybe<TargetField>;
};

export type IdentityMappingUpdate = {
  function?: InputMaybe<IdentityFunction>;
  modelFieldID: Scalars['UUID']['input'];
  newField: Scalars['Boolean']['input'];
  remoteFieldTypeId?: InputMaybe<Scalars['String']['input']>;
  targetFieldID: Scalars['ID']['input'];
};

export type IntObj = {
  __typename?: 'IntObj';
  int: Scalars['Int']['output'];
};

export type IntegrationSchedule = {
  __typename?: 'IntegrationSchedule';
  connectionId: Scalars['UUID']['output'];
  connectionType: Scalars['String']['output'];
  syncId: Scalars['UUID']['output'];
};

export type IntercomTargetConfiguration = {
  __typename?: 'IntercomTargetConfiguration';
  writeNullValues: Scalars['Boolean']['output'];
};

export type IntercomConfiguration = {
  __typename?: 'IntercomConfiguration';
  object: Scalars['String']['output'];
};

export type IroncladConfiguration = {
  __typename?: 'IroncladConfiguration';
  object: Scalars['String']['output'];
};

export type ItemizedSchedule = {
  __typename?: 'ItemizedSchedule';
  item: Scalars['String']['output'];
  schedule: Schedule;
};

export type ItemizedScheduleUpdate = {
  item: Scalars['String']['input'];
  schedule: ScheduleUpdate;
};

export type KlaviyoConfiguration = {
  __typename?: 'KlaviyoConfiguration';
  streamID: Scalars['String']['output'];
};

export type KustomerConfiguration = {
  __typename?: 'KustomerConfiguration';
  object: Scalars['String']['output'];
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type LabelMetadata = {
  __typename?: 'LabelMetadata';
  id: Scalars['UUID']['output'];
  modelCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LabelUpdate = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type LoggingConfiguration = {
  __typename?: 'LoggingConfiguration';
  acl: Array<Action>;
  conf: Scalars['Map']['output'];
  enabled: Scalars['Boolean']['output'];
  storageType: StorageType;
  updatedAt: Scalars['Time']['output'];
};

export type LoggingConfigurationUpdate = {
  conf: Scalars['Map']['input'];
  enabled: Scalars['Boolean']['input'];
  storageType: StorageType;
};

export type MailerCheckEnrichmentConfiguration = {
  __typename?: 'MailerCheckEnrichmentConfiguration';
  object: Scalars['String']['output'];
};

export type ManualSchedule = BulkSchedule & Schedule & {
  __typename?: 'ManualSchedule';
  frequency: Frequency;
};

export type MappedFieldUpdate = {
  modelFieldID: Scalars['UUID']['input'];
  newField: Scalars['Boolean']['input'];
  remoteFieldTypeId?: InputMaybe<Scalars['String']['input']>;
  syncMode?: InputMaybe<SyncMode>;
  targetFieldID: Scalars['String']['input'];
};

export type MappedOverrideFieldUpdate = {
  overrideValue: Scalars['String']['input'];
  syncMode?: InputMaybe<SyncMode>;
  targetFieldID: Scalars['ID']['input'];
};

export type MarketoConfiguration = {
  __typename?: 'MarketoConfiguration';
  list?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
};

export type MarketoTargetConfiguration = {
  __typename?: 'MarketoTargetConfiguration';
  writeNullValues: Scalars['Boolean']['output'];
};

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Model = {
  __typename?: 'Model';
  fieldsetCount: Scalars['Int']['output'];
  fieldsets: Array<Fieldset>;
};

export type ModelField = {
  __typename?: 'ModelField';
  description: Scalars['String']['output'];
  example?: Maybe<Scalars['Any']['output']>;
  fieldset: Fieldset;
  filterFunctions: Array<SupportedFilterFunction>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  relatedTo?: Maybe<ModelField>;
  sourceName: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  type: FieldType;
  userAdded: Scalars['Boolean']['output'];
};

export type ModelFilter = {
  __typename?: 'ModelFilter';
  field: Scalars['String']['output'];
  function: FilterFunction;
  value?: Maybe<Scalars['Any']['output']>;
};

export type ModelSuccessOrConfirmation = ConfirmationRequest | Model;

export type MongoDbConfiguration = {
  __typename?: 'MongoDBConfiguration';
  aggregation?: Maybe<Scalars['String']['output']>;
  collection: Scalars['String']['output'];
  database: Scalars['String']['output'];
  query?: Maybe<Scalars['String']['output']>;
  trackingColumns: Array<ModelField>;
};

export type MultiSchedule = BulkSchedule & {
  __typename?: 'MultiSchedule';
  frequency: Frequency;
  multi?: Maybe<MultiScheduleConfiguration>;
};

export type MultiScheduleConfiguration = {
  __typename?: 'MultiScheduleConfiguration';
  schedules: Array<ItemizedSchedule>;
  type: MultiScheduleType;
};

export enum MultiScheduleType {
  Incremental = 'incremental'
}

export type MultiScheduleUpdate = {
  schedules: Array<ItemizedScheduleUpdate>;
  type: MultiScheduleType;
};

export type MultiTargetConfiguration = {
  __typename?: 'MultiTargetConfiguration';
  createRecordsAs: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateBulkSync: BulkSync;
  activateSync: Sync;
  addUser: Array<User>;
  autoErrorRetry: Scalars['Boolean']['output'];
  cancelBulkSync?: Maybe<BulkSync>;
  cancelContinuation: Scalars['Boolean']['output'];
  cancelSync: Sync;
  cloneSync: Sync;
  cloneTag: PermissionTag;
  createApiKey: ApiKeyRecord;
  createBulkSync: BulkSyncSuccessOrConfirmation;
  createConnection: Connection;
  createFieldset: FieldsetUpdateResult;
  createLabel: Label;
  createRole: PermissionRole;
  createSync: Sync;
  createTag: PermissionTag;
  deleteApiKey: Scalars['Boolean']['output'];
  deleteBulkSync: DeleteSyncResponse;
  deleteConnection?: Maybe<DeleteConnectionResponse>;
  deleteFieldset: ModelSuccessOrConfirmation;
  deleteLabel: DeleteLabelResponse;
  deleteRole: Scalars['Boolean']['output'];
  deleteSync?: Maybe<DeleteSyncResponse>;
  deleteTag?: Maybe<TagDeletedSuccessResponse>;
  deleteUser: Scalars['UUID']['output'];
  partialFailureEmails: Scalars['Boolean']['output'];
  refreshCachedObject: Scalars['Boolean']['output'];
  refreshCachedObjects: Scalars['Boolean']['output'];
  requestIntegration: RequestIntegrationResponse;
  saveBuffer: Scalars['Boolean']['output'];
  setEventTargetConnection: EventConnectionResponse;
  setLoggingConfiguration: LoggingConfiguration;
  setRoles: Scalars['Boolean']['output'];
  setTags: ResourceTagsAndAcl;
  startBulkSync: BulkSyncSuccessOrConfirmation;
  startSync: Sync;
  updateBulkSync: BulkSyncSuccessOrConfirmation;
  updateConnection: Connection;
  updateEmailRecipientsForNotification: Array<Notification>;
  updateField: Model;
  updateFieldset: FieldsetUpdateResult;
  updateLabel: Label;
  updateRole: PermissionRole;
  updateSync: Sync;
  updateTag: PermissionTag;
  updateUser: Array<User>;
  updateUserEmailNotification?: Maybe<Notification>;
  userViewedSync: SyncViewedResponse;
  warningNotifications: Scalars['Boolean']['output'];
};


export type MutationActivateBulkSyncArgs = {
  active: Scalars['Boolean']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationActivateSyncArgs = {
  active: Scalars['Boolean']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationAddUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationAutoErrorRetryArgs = {
  state: Scalars['Boolean']['input'];
  syncId: Scalars['UUID']['input'];
};


export type MutationCancelBulkSyncArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationCancelContinuationArgs = {
  continuation: Scalars['UUID']['input'];
};


export type MutationCancelSyncArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationCloneSyncArgs = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  targetConnectionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCloneTagArgs = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateApiKeyArgs = {
  replaceExisting?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateBulkSyncArgs = {
  confirmation?: InputMaybe<Scalars['ConfirmationKey']['input']>;
  sync: BulkSyncUpdate;
  tags: Array<Scalars['UUID']['input']>;
};


export type MutationCreateConnectionArgs = {
  connection: NewConnection;
  tags?: InputMaybe<Array<Scalars['UUID']['input']>>;
  type: Scalars['String']['input'];
};


export type MutationCreateFieldsetArgs = {
  connection: Scalars['UUID']['input'];
};


export type MutationCreateLabelArgs = {
  label: NewLabel;
};


export type MutationCreateRoleArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateTagArgs = {
  name: Scalars['String']['input'];
  policy: PolicyInput;
};


export type MutationDeleteBulkSyncArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteConnectionArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteFieldsetArgs = {
  confirmation?: InputMaybe<Scalars['ConfirmationKey']['input']>;
  id: Scalars['UUID']['input'];
};


export type MutationDeleteLabelArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteSyncArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationPartialFailureEmailsArgs = {
  state: Scalars['Boolean']['input'];
  syncId: Scalars['UUID']['input'];
};


export type MutationRefreshCachedObjectArgs = {
  connectionID: Scalars['UUID']['input'];
  schemaID: Scalars['String']['input'];
};


export type MutationRequestIntegrationArgs = {
  request: Scalars['String']['input'];
};


export type MutationSaveBufferArgs = {
  buffer: Scalars['String']['input'];
  connID: Scalars['UUID']['input'];
};


export type MutationSetEventTargetConnectionArgs = {
  connId?: InputMaybe<Scalars['UUID']['input']>;
  enabled: Scalars['Boolean']['input'];
};


export type MutationSetLoggingConfigurationArgs = {
  conf: LoggingConfigurationUpdate;
};


export type MutationSetRolesArgs = {
  roleIDs: Array<Scalars['UUID']['input']>;
  userID: Scalars['UUID']['input'];
};


export type MutationSetTagsArgs = {
  resource: ResourceInput;
  tagIDs: Array<Scalars['UUID']['input']>;
};


export type MutationStartBulkSyncArgs = {
  confirmation?: InputMaybe<Scalars['ConfirmationKey']['input']>;
  id: Scalars['UUID']['input'];
  opts?: InputMaybe<StartBulkSyncOptions>;
};


export type MutationStartSyncArgs = {
  id: Scalars['UUID']['input'];
  opts?: InputMaybe<StartSyncOptions>;
};


export type MutationUpdateBulkSyncArgs = {
  id: Scalars['UUID']['input'];
  sync: BulkSyncUpdate;
  tags: Array<Scalars['UUID']['input']>;
};


export type MutationUpdateConnectionArgs = {
  connection: ConnectionUpdate;
  tags: Array<Scalars['UUID']['input']>;
};


export type MutationUpdateEmailRecipientsForNotificationArgs = {
  emails: Array<Scalars['Email']['input']>;
  event: Scalars['Event']['input'];
  scope: Scalars['UUID']['input'];
};


export type MutationUpdateFieldArgs = {
  update: FieldUpdate;
};


export type MutationUpdateFieldsetArgs = {
  enrichments?: InputMaybe<Array<EnrichmentUpdate>>;
  fields: Array<FieldUpdate>;
  id: Scalars['UUID']['input'];
  labels: Array<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  primaryKey?: InputMaybe<Scalars['UUID']['input']>;
  relatedTo: Array<NewRelationship>;
  tags: Array<Scalars['UUID']['input']>;
  update: Scalars['Map']['input'];
};


export type MutationUpdateLabelArgs = {
  label: LabelUpdate;
};


export type MutationUpdateRoleArgs = {
  update: PermissionRoleUpdate;
};


export type MutationUpdateSyncArgs = {
  tags: Array<Scalars['UUID']['input']>;
  update: SyncUpdate;
};


export type MutationUpdateTagArgs = {
  update: PermissionTagUpdate;
};


export type MutationUpdateUserArgs = {
  update: UserUpdate;
};


export type MutationUpdateUserEmailNotificationArgs = {
  enabled: Scalars['Boolean']['input'];
  event: Scalars['Event']['input'];
  scope: Scalars['UUID']['input'];
};


export type MutationUserViewedSyncArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationWarningNotificationsArgs = {
  state: Scalars['Boolean']['input'];
  syncId: Scalars['UUID']['input'];
};

export type MySqlDestinationConfiguration = {
  __typename?: 'MySQLDestinationConfiguration';
  OK?: Maybe<Scalars['String']['output']>;
};

export type Namespace = {
  __typename?: 'Namespace';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schemas: Array<Schema>;
};


export type NamespaceSchemasArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NetsuiteConfiguration = {
  __typename?: 'NetsuiteConfiguration';
  object: Scalars['String']['output'];
};

export type NewConnection = {
  configuration: Scalars['Map']['input'];
  files: Array<FileUpload>;
  name: Scalars['String']['input'];
};

export type NewFilter = {
  fieldID: Scalars['ID']['input'];
  fieldType: FieldReferenceType;
  function: FilterFunction;
  label: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Value']['input']>;
};

export type NewLabel = {
  name: Scalars['String']['input'];
};

export type NewOverride = {
  fieldID: Scalars['UUID']['input'];
  function: FilterFunction;
  overrideValue: Scalars['Value']['input'];
  value?: InputMaybe<Scalars['Value']['input']>;
};

export type NewParameterValue = {
  source: ParameterValueSource;
  value?: InputMaybe<Scalars['Any']['input']>;
};

export type NewRelationship = {
  from: Scalars['ID']['input'];
  to: Scalars['ID']['input'];
};

export type NextPagePagination = {
  __typename?: 'NextPagePagination';
  nextPage: Scalars['String']['output'];
  persistPagination: Scalars['Boolean']['output'];
  source: NextSource;
};

export enum NextSource {
  Body = 'body',
  Header = 'header'
}

export type NoBulkConfiguration = {
  __typename?: 'NoBulkConfiguration';
  ok: Scalars['Boolean']['output'];
};

export type NoConfiguration = {
  __typename?: 'NoConfiguration';
  ok: Scalars['Boolean']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  event: Scalars['Event']['output'];
  recipientId: Scalars['String']['output'];
  recipientType: RecipientType;
  scope: Scalars['UUID']['output'];
};

export enum NumericComparator {
  Between = 'between',
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  Neq = 'neq'
}

export type OffsetPagination = {
  __typename?: 'OffsetPagination';
  limitParameterName: Scalars['String']['output'];
  offsetParameterName?: Maybe<Scalars['String']['output']>;
  pageSize: Scalars['Int']['output'];
  recordLimit: Scalars['Int']['output'];
};

export enum Operation {
  BulkSyncDestination = 'BulkSyncDestination',
  BulkSyncSource = 'BulkSyncSource',
  Context = 'CONTEXT',
  Checkpointable = 'Checkpointable',
  DestinationRequireConfiguration = 'DestinationRequireConfiguration',
  EnrichmentBackend = 'EnrichmentBackend',
  Fieldsetquery = 'FIELDSETQUERY',
  Filters = 'Filters',
  IdentityFunctions = 'IdentityFunctions',
  PolytomicConnection = 'PolytomicConnection',
  RequiresIpWhitelist = 'RequiresIPWhitelist',
  Scheduler = 'Scheduler',
  SupportsSql = 'SupportsSQL',
  Target = 'TARGET'
}

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrgStats = {
  __typename?: 'OrgStats';
  activeBulkSyncs: Scalars['Int']['output'];
  activeSyncs: Scalars['Int']['output'];
  connections: Scalars['Int']['output'];
  usedModels: Scalars['Int']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  chargebee?: Maybe<Chargebee>;
  createdAt: Scalars['Time']['output'];
  createdBy: User;
  features: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  workos?: Maybe<WorkOs>;
};

export enum OutputFormat {
  Csv = 'csv',
  Json = 'json',
  Parquet = 'parquet'
}

export type OutreachConfiguration = {
  __typename?: 'OutreachConfiguration';
  streamID: Scalars['String']['output'];
};

export type Override = {
  __typename?: 'Override';
  field?: Maybe<ModelField>;
  function?: Maybe<FilterFunction>;
  overrideValue: Scalars['Value']['output'];
  value?: Maybe<Scalars['Value']['output']>;
};

export type OverrideFieldMapping = {
  __typename?: 'OverrideFieldMapping';
  overrideValue?: Maybe<Scalars['String']['output']>;
  syncMode?: Maybe<SyncMode>;
  target?: Maybe<TargetField>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type PairObj = {
  __typename?: 'PairObj';
  elem1: Scalars['String']['output'];
  elem2: Scalars['String']['output'];
};

export enum ParameterLocation {
  Body = 'Body',
  Header = 'Header',
  QueryString = 'QueryString'
}

export type ParameterValue = {
  __typename?: 'ParameterValue';
  displayValue: Scalars['String']['output'];
  source: ParameterValueSource;
  value?: Maybe<Scalars['Any']['output']>;
};

export enum ParameterValueSource {
  Formula = 'FORMULA',
  Literal = 'LITERAL'
}

export type PartialConnectionUpdate = {
  configuration: Scalars['Map']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type PartitionKeyOption = {
  __typename?: 'PartitionKeyOption';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type PermissionRole = {
  __typename?: 'PermissionRole';
  acl: Array<Action>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  system: Scalars['Boolean']['output'];
};

export type PermissionRoleUpdate = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type PermissionTag = {
  __typename?: 'PermissionTag';
  acl: Array<Action>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  policy: Policy;
  system: Scalars['Boolean']['output'];
  systemResourceTypes: Array<ResourceType>;
};

export type PermissionTagUpdate = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  policy: PolicyInput;
};

export type PipedriveConfiguration = {
  __typename?: 'PipedriveConfiguration';
  includeDeleted: Scalars['Boolean']['output'];
  streamID: Scalars['String']['output'];
};

export type Policy = {
  __typename?: 'Policy';
  policyActions: Array<PolicyAction>;
  tagID: Scalars['UUID']['output'];
};

export type PolicyAction = {
  __typename?: 'PolicyAction';
  action: Action;
  roleIDs: Array<Scalars['UUID']['output']>;
};

export type PolicyActionInput = {
  action: Action;
  roleIDs: Array<Scalars['UUID']['input']>;
};

export type PolicyInput = {
  policyActions: Array<PolicyActionInput>;
};

export type Poller = {
  __typename?: 'Poller';
  id: Scalars['ID']['output'];
  interval: Scalars['Int']['output'];
};

export type PostgresqlSourceConfiguration = {
  __typename?: 'PostgresqlSourceConfiguration';
  replicationSlot?: Maybe<Scalars['String']['output']>;
};

export type PredictLeadsEnrichmentConfiguration = {
  __typename?: 'PredictLeadsEnrichmentConfiguration';
  object: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  activityLog: Array<ActivityLog>;
  activityLogConnection: ActivityLogConnection;
  activityTypes: Array<Scalars['String']['output']>;
  allActivityTypes: Array<Scalars['String']['output']>;
  allLabels: Array<Label>;
  allRoles: Array<PermissionRole>;
  allTags: Array<PermissionTag>;
  applyConnectionUpdate: Connection;
  applyFieldsetUpdate: FieldsetOrContinuation;
  autoErrorRetry: Scalars['Boolean']['output'];
  bulkDestinationForConnection?: Maybe<BulkDestinationConnection>;
  bulkSourceForConnection: BulkSourceConnection;
  bulkSync: BulkSync;
  bulkSyncExecutions: BulkSyncExecutionConnection;
  bulkSyncExecutionsPageInfo: BulkSyncPageInfo;
  bulkSyncs: Array<BulkSync>;
  connection: Connection;
  connectionParameterCompletions: CompletionResponse;
  connectionType: ConnectionType;
  connectionTypes: Array<ConnectionType>;
  connections: Array<Connection>;
  currentUserCreatePermissions: Array<ResourceType>;
  currentUserHasCreatePermission: Scalars['Boolean']['output'];
  dataArchitectureQuery: DataArchitectureResponseOrContinuation;
  dataliteMetadata: Array<Maybe<DataliteSchemaMetadata>>;
  enrichmentSchema: Scalars['JSONSchema']['output'];
  exploreCount: QueryResultCountOrPoller;
  explorePaged: QueryResultConnectionOrPoller;
  exportQuery: ExportSqlResultOrContinuation;
  exportQueryAsync: Scalars['String']['output'];
  field: ModelField;
  fieldset: Fieldset;
  fieldsetsForLabel: Array<Fieldset>;
  getApiKey: GetApiKeyResponse;
  getBuffer: Scalars['String']['output'];
  getChargebeePortalJson: ChargebeePortalPacket;
  getConfiguration?: Maybe<Configuration>;
  getEnrichers: Array<Connection>;
  getEventTargetConnection: EventConnectionResponse;
  getFiltersForAllTypes: Array<SupportedFilterFunctionsForType>;
  getFiltersForType: SupportedFilterFunctionsForType;
  getLoggingConfiguration: LoggingConfiguration;
  getPolicy: Policy;
  identityManagementPortalLink: Scalars['String']['output'];
  isAllowed: Scalars['Boolean']['output'];
  jsonPathResult: Scalars['String']['output'];
  labelMetadata: Array<LabelMetadata>;
  me: User;
  model: Model;
  notificationRecipients: Array<Notification>;
  orgStats: OrgStats;
  partialFailureEmails: Scalars['Boolean']['output'];
  peekSyncs: Array<SyncPeek>;
  previewSync: SyncRecordsOrContinuation;
  queryHistory: Array<Maybe<HistoryQuery>>;
  reachableFieldsets: Array<ModelField>;
  refreshCachedModel: ExploreStatusOrPoller;
  rolesForUser: Array<PermissionRole>;
  rolesForUsers: Array<UserRoles>;
  runQuery: SqlRunnerResultOrPoller;
  sanitizeIdentifier: Scalars['String']['output'];
  schemaInfo: Array<SqlRunnerSchemaV2>;
  schemaInfoMeta: SqlRunnerSchemaV2Meta;
  schemas: SchemasResponse;
  searchSchema: Array<SqlRunnerSchemaV2>;
  suggestModelName: Scalars['String']['output'];
  sync: Sync;
  syncExecutionStatus: SyncExecution;
  syncExecutions: SyncExecutionConnection;
  syncExecutionsPageInfo: PageInfo;
  syncs: Array<Sync>;
  syncsForField: Array<Sync>;
  syncsForModel: Array<Sync>;
  tagsForResource: Array<PermissionTag>;
  targetObject: TargetObject;
  targetObjectFields: Array<TargetField>;
  usedByTag: Array<TagUsedBy>;
  users: Array<User>;
  warningNotifications: Scalars['Boolean']['output'];
  webhookRequests: Array<Request>;
};


export type QueryActivityLogArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  actorID?: InputMaybe<Scalars['UUID']['input']>;
  actorType?: InputMaybe<ActorType>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAssociated?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  objectID: Scalars['UUID']['input'];
  rangeEnd?: InputMaybe<Scalars['Time']['input']>;
  rangeStart?: InputMaybe<Scalars['Time']['input']>;
};


export type QueryActivityLogConnectionArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  actorID?: InputMaybe<Scalars['UUID']['input']>;
  actorType?: InputMaybe<ActorType>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAssociated?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  objectID: Scalars['UUID']['input'];
  rangeEnd?: InputMaybe<Scalars['Time']['input']>;
  rangeStart?: InputMaybe<Scalars['Time']['input']>;
};


export type QueryActivityTypesArgs = {
  objectID: Scalars['UUID']['input'];
  objectType: Scalars['String']['input'];
};


export type QueryAllActivityTypesArgs = {
  objectType: Scalars['String']['input'];
};


export type QueryAllLabelsArgs = {
  entityType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryApplyConnectionUpdateArgs = {
  connection: PartialConnectionUpdate;
  type: Scalars['String']['input'];
};


export type QueryApplyFieldsetUpdateArgs = {
  continuation: Scalars['ID']['input'];
  enrichments?: InputMaybe<Array<EnrichmentUpdate>>;
  fields?: InputMaybe<Array<FieldUpdate>>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  primaryKey?: InputMaybe<Scalars['UUID']['input']>;
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
  relatedTo?: InputMaybe<Array<NewRelationship>>;
  update: Scalars['Map']['input'];
};


export type QueryAutoErrorRetryArgs = {
  syncId: Scalars['UUID']['input'];
};


export type QueryBulkDestinationForConnectionArgs = {
  connectionID: Scalars['UUID']['input'];
  destinationConfiguration?: InputMaybe<Scalars['Map']['input']>;
};


export type QueryBulkSourceForConnectionArgs = {
  connectionID: Scalars['UUID']['input'];
  continuation: Scalars['ID']['input'];
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBulkSyncArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryBulkSyncExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<BulkExecutionsSort>;
  syncID: Scalars['UUID']['input'];
  where?: InputMaybe<BulkExecutionsCondition>;
};


export type QueryBulkSyncExecutionsPageInfoArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<BulkExecutionsSort>;
  syncID: Scalars['UUID']['input'];
  where?: InputMaybe<BulkExecutionsCondition>;
};


export type QueryConnectionArgs = {
  id: Scalars['UUID']['input'];
  includeUnsaved?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryConnectionParameterCompletionsArgs = {
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  parameters: Scalars['Map']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type QueryConnectionTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryConnectionsArgs = {
  operations?: InputMaybe<Array<Operation>>;
};


export type QueryCurrentUserHasCreatePermissionArgs = {
  resourceType: ResourceType;
};


export type QueryDataArchitectureQueryArgs = {
  connectionID: Scalars['UUID']['input'];
  continuation: Scalars['ID']['input'];
  objectType: Scalars['String']['input'];
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
  searchPath: Scalars['String']['input'];
};


export type QueryEnrichmentSchemaArgs = {
  configuration?: InputMaybe<Scalars['Map']['input']>;
  connectionID: Scalars['UUID']['input'];
};


export type QueryExploreCountArgs = {
  conditionLogic?: InputMaybe<Scalars['String']['input']>;
  conditions?: InputMaybe<Array<NewFilter>>;
  continuation: Scalars['ID']['input'];
  fields: Array<Scalars['UUID']['input']>;
};


export type QueryExplorePagedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  conditionLogic?: InputMaybe<Scalars['String']['input']>;
  conditions?: InputMaybe<Array<NewFilter>>;
  continuation: Scalars['ID']['input'];
  fields: Array<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<QueryOrderBy>>;
};


export type QueryExportQueryArgs = {
  connectionID: Scalars['UUID']['input'];
  continuation: Scalars['ID']['input'];
  query: Scalars['String']['input'];
  visibleOnly: Scalars['Boolean']['input'];
};


export type QueryExportQueryAsyncArgs = {
  connectionID: Scalars['UUID']['input'];
  query: Scalars['String']['input'];
};


export type QueryFieldArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryFieldsetArgs = {
  id: Scalars['UUID']['input'];
  include: FieldState;
};


export type QueryFieldsetsForLabelArgs = {
  labelId: Scalars['UUID']['input'];
};


export type QueryGetBufferArgs = {
  connID: Scalars['UUID']['input'];
};


export type QueryGetChargebeePortalJsonArgs = {
  returnUri: Scalars['String']['input'];
};


export type QueryGetFiltersForTypeArgs = {
  type: FieldType;
};


export type QueryGetPolicyArgs = {
  tagID: Scalars['UUID']['input'];
};


export type QueryIsAllowedArgs = {
  action: Action;
  resource: ResourceInput;
  userID: Scalars['UUID']['input'];
};


export type QueryJsonPathResultArgs = {
  jsonPath: Scalars['String']['input'];
  plaintext: Scalars['String']['input'];
};


export type QueryModelArgs = {
  include: FieldState;
};


export type QueryNotificationRecipientsArgs = {
  event: Scalars['Event']['input'];
  scope: Scalars['UUID']['input'];
};


export type QueryPartialFailureEmailsArgs = {
  syncId: Scalars['UUID']['input'];
};


export type QueryPreviewSyncArgs = {
  continuation: Scalars['ID']['input'];
  id: Scalars['UUID']['input'];
  previewFilters?: InputMaybe<Array<NewFilter>>;
  update?: InputMaybe<SyncUpdate>;
};


export type QueryQueryHistoryArgs = {
  connID: Scalars['UUID']['input'];
};


export type QueryReachableFieldsetsArgs = {
  field: Scalars['UUID']['input'];
};


export type QueryRefreshCachedModelArgs = {
  continuation: Scalars['ID']['input'];
  fieldsetID: Scalars['UUID']['input'];
};


export type QueryRolesForUserArgs = {
  userID: Scalars['UUID']['input'];
};


export type QueryRolesForUsersArgs = {
  userIDs: Array<Scalars['UUID']['input']>;
};


export type QueryRunQueryArgs = {
  connectionID: Scalars['UUID']['input'];
  continuation: Scalars['ID']['input'];
  query: Scalars['String']['input'];
};


export type QuerySanitizeIdentifierArgs = {
  Identifier: Scalars['String']['input'];
  connectionId: Scalars['UUID']['input'];
};


export type QuerySchemaInfoArgs = {
  connectionID: Scalars['UUID']['input'];
  database?: InputMaybe<Scalars['String']['input']>;
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
  table?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySchemaInfoMetaArgs = {
  connectionID: Scalars['UUID']['input'];
};


export type QuerySchemasArgs = {
  connectionID: Scalars['UUID']['input'];
};


export type QuerySearchSchemaArgs = {
  connectionID: Scalars['UUID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySuggestModelNameArgs = {
  id: Scalars['UUID']['input'];
  update: Scalars['Map']['input'];
};


export type QuerySyncArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySyncExecutionStatusArgs = {
  executionID: Scalars['UUID']['input'];
};


export type QuerySyncExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  syncID: Scalars['UUID']['input'];
  where?: InputMaybe<ExecutionsCondition>;
};


export type QuerySyncExecutionsPageInfoArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  syncID: Scalars['UUID']['input'];
  where?: InputMaybe<ExecutionsCondition>;
};


export type QuerySyncsForFieldArgs = {
  fieldId: Scalars['UUID']['input'];
};


export type QuerySyncsForModelArgs = {
  modelId: Scalars['UUID']['input'];
};


export type QueryTagsForResourceArgs = {
  resource: ResourceInput;
};


export type QueryTargetObjectArgs = {
  connectionID: Scalars['UUID']['input'];
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
  targetObject: Scalars['ID']['input'];
};


export type QueryTargetObjectFieldsArgs = {
  connectionID: Scalars['UUID']['input'];
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
  targetObject: Scalars['ID']['input'];
};


export type QueryUsedByTagArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryWarningNotificationsArgs = {
  syncId: Scalars['UUID']['input'];
};


export type QueryWebhookRequestsArgs = {
  refID: Scalars['UUID']['input'];
};

export type QueryOrderBy = {
  direction: OrderByDirection;
  field: Scalars['UUID']['input'];
};

export type QueryResult = {
  __typename?: 'QueryResult';
  continuation: Scalars['ID']['output'];
  debugQuery: Scalars['String']['output'];
  fields: Array<ModelField>;
  results: Array<Scalars['Map']['output']>;
};

export type QueryResultConnection = {
  __typename?: 'QueryResultConnection';
  debugQuery: Scalars['String']['output'];
  edges?: Maybe<Array<QueryResultEdge>>;
  fields: Array<ModelField>;
  pageInfo: PageInfo;
};

export type QueryResultConnectionOrPoller = Poller | QueryResultConnection;

export type QueryResultCount = {
  __typename?: 'QueryResultCount';
  count: Scalars['Int']['output'];
  debugQuery: Scalars['String']['output'];
};

export type QueryResultCountOrPoller = Poller | QueryResultCount;

export type QueryResultEdge = {
  __typename?: 'QueryResultEdge';
  cursor: Scalars['String']['output'];
  node: Scalars['Map']['output'];
};

export type QueryResultOrPoller = Poller | QueryResult;

export type QuickBooksConfiguration = {
  __typename?: 'QuickBooksConfiguration';
  dateMacro?: Maybe<Scalars['String']['output']>;
  object: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};

export enum RecipientType {
  Email = 'email'
}

export type Relationship = {
  __typename?: 'Relationship';
  from: ModelField;
  to: ModelField;
};

export type RelationshipChangeEvent = {
  __typename?: 'RelationshipChangeEvent';
  fieldID: Scalars['UUID']['output'];
  fieldName: Scalars['String']['output'];
  foreignFieldID: Scalars['UUID']['output'];
  foreignFieldName: Scalars['String']['output'];
  foreignFieldsetID: Scalars['UUID']['output'];
  foreignFieldsetName: Scalars['String']['output'];
  foreignFieldsetType: Scalars['String']['output'];
};

export type RemoteFieldType = {
  __typename?: 'RemoteFieldType';
  label: Scalars['String']['output'];
  ptType: FieldType;
  remoteId: Scalars['String']['output'];
};

export type ReplyioConfiguration = {
  __typename?: 'ReplyioConfiguration';
  streamID: Scalars['String']['output'];
};

export type Request = {
  __typename?: 'Request';
  body: Scalars['String']['output'];
  headers: Array<Header>;
  id: Scalars['ID']['output'];
  method: Scalars['String']['output'];
  referenceID: Scalars['UUID']['output'];
  response?: Maybe<Response>;
  url: Scalars['String']['output'];
};

export type RequestIntegrationResponse = {
  __typename?: 'RequestIntegrationResponse';
  ok: Scalars['Boolean']['output'];
};

export type RequestParameter = {
  __typename?: 'RequestParameter';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Any']['output']>;
};

export type ResourceInput = {
  create: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  type: ResourceType;
};

export type ResourceTagsAndAcl = {
  __typename?: 'ResourceTagsAndACL';
  acl: Array<Action>;
  tags: Array<PermissionTag>;
};

export enum ResourceType {
  Bulksync = 'bulksync',
  Connection = 'connection',
  Model = 'model',
  Role = 'role',
  Setting = 'setting',
  Sync = 'sync',
  Tag = 'tag',
  User = 'user'
}

export type Response = {
  __typename?: 'Response';
  body?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  headers: Array<Header>;
  statusCode?: Maybe<Scalars['Int']['output']>;
};

export type RunAfterSchedule = Schedule & {
  __typename?: 'RunAfterSchedule';
  frequency: Frequency;
};

export type RunAfterSyncPeek = {
  __typename?: 'RunAfterSyncPeek';
  Type: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type SqlConfiguration = {
  __typename?: 'SQLConfiguration';
  query?: Maybe<Scalars['String']['output']>;
  table: Scalars['String']['output'];
  trackingColumns: Array<ModelField>;
  view?: Maybe<Scalars['String']['output']>;
};

export enum SalesforceApi {
  Automatic = 'Automatic',
  Bulk = 'Bulk',
  Rest = 'REST'
}

export type SalesforceConfiguration = {
  __typename?: 'SalesforceConfiguration';
  includeDeleted: Scalars['Boolean']['output'];
  query?: Maybe<Scalars['String']['output']>;
  table: Scalars['String']['output'];
};

export type SalesforceLeadTargetConfiguration = {
  __typename?: 'SalesforceLeadTargetConfiguration';
  bulkBatchSize?: Maybe<Scalars['Int']['output']>;
  concurrencyMode: ConcurrencyMode;
  ignoreInvalidReferences: Scalars['Boolean']['output'];
  ingestionAPI?: Maybe<SalesforceApi>;
  insertNullValues: Scalars['Boolean']['output'];
  onlySyncDifferent: Scalars['Boolean']['output'];
  skipConverted: Scalars['Boolean']['output'];
};

export type SalesforceTargetConfiguration = {
  __typename?: 'SalesforceTargetConfiguration';
  bulkBatchSize?: Maybe<Scalars['Int']['output']>;
  concurrencyMode: ConcurrencyMode;
  ignoreInvalidReferences: Scalars['Boolean']['output'];
  ingestionAPI?: Maybe<SalesforceApi>;
  insertNullValues: Scalars['Boolean']['output'];
  onlySyncDifferent: Scalars['Boolean']['output'];
  sortBatchesBy?: Maybe<Scalars['String']['output']>;
};

export type ScamalyticsConfiguration = {
  __typename?: 'ScamalyticsConfiguration';
  object: Scalars['String']['output'];
};

export type Schedule = {
  frequency: Frequency;
};

export type ScheduleOption = {
  __typename?: 'ScheduleOption';
  configuration?: Maybe<FormDefinition>;
  description: Scalars['String']['output'];
  frequency: Frequency;
  label: Scalars['String']['output'];
};

export type ScheduleUpdate = {
  connectionId?: InputMaybe<Scalars['UUID']['input']>;
  dayOfMonth?: InputMaybe<Scalars['String']['input']>;
  dayOfWeek?: InputMaybe<Scalars['String']['input']>;
  frequency: Frequency;
  hour?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  minute?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
};

export type Schema = {
  __typename?: 'Schema';
  fields: Array<Field>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outputName?: Maybe<Scalars['String']['output']>;
  slowMode: Scalars['Boolean']['output'];
};


export type SchemaFieldsArgs = {
  filters?: InputMaybe<FieldFilters>;
};

export enum SchemaExecutionStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Created = 'created',
  Exporting = 'exporting',
  Failed = 'failed',
  Running = 'running',
  Scheduled = 'scheduled'
}

export type SchemaFilters = {
  fieldSearch?: InputMaybe<Scalars['String']['input']>;
  hasFieldsOfSyncType?: InputMaybe<FieldSyncType>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SchemaIdentifier = {
  __typename?: 'SchemaIdentifier';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum SchemaRefreshStatus {
  Cached = 'cached',
  Errors = 'errors',
  Initializing = 'initializing',
  Refreshing = 'refreshing',
  Uninitialized = 'uninitialized'
}

export type SchemasResponse = {
  __typename?: 'SchemasResponse';
  schemas: Array<SqlRunnerSchema>;
};

export type SeamAIConfiguration = {
  __typename?: 'SeamAIConfiguration';
  enricher: Scalars['String']['output'];
};

export enum SelectionState {
  All = 'all',
  None = 'none',
  Partial = 'partial'
}

export enum SelectiveMode {
  IncrementalFields = 'incrementalFields',
  None = 'none',
  NonincrementalFields = 'nonincrementalFields'
}

export type SequentialPagePagination = {
  __typename?: 'SequentialPagePagination';
  pageParameterName: Scalars['String']['output'];
};

export type SheetSelection = {
  __typename?: 'SheetSelection';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type ShipbobConfiguration = {
  __typename?: 'ShipbobConfiguration';
  object: Scalars['String']['output'];
};

export type ShopifyConfiguration = {
  __typename?: 'ShopifyConfiguration';
  object: Scalars['String']['output'];
};

export type SnowflakeTargetConfiguration = {
  __typename?: 'SnowflakeTargetConfiguration';
  preserveTableOnResync: Scalars['Boolean']['output'];
  writeNullValues: Scalars['Boolean']['output'];
};

export type SqlRunnerColumnSchema = {
  __typename?: 'SqlRunnerColumnSchema';
  columnName: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  type: FieldType;
};

export type SqlRunnerResult = {
  __typename?: 'SqlRunnerResult';
  columnNames: Array<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  rows: Array<Array<Scalars['String']['output']>>;
};

export type SqlRunnerResultOrPoller = Poller | SqlRunnerResult;

export type SqlRunnerSchema = {
  __typename?: 'SqlRunnerSchema';
  columns: Array<SqlRunnerColumnSchema>;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SqlRunnerSchemaV2 = {
  __typename?: 'SqlRunnerSchemaV2';
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schemas: Array<DatabaseSchema>;
};

export type SqlRunnerSchemaV2Meta = {
  __typename?: 'SqlRunnerSchemaV2Meta';
  databaseLabel: Scalars['String']['output'];
  schemaLabel: Scalars['String']['output'];
  supportsDatabaseSelection: Scalars['Boolean']['output'];
  tableLabel: Scalars['String']['output'];
};

export type StartBulkSyncOptions = {
  resync?: InputMaybe<Scalars['Boolean']['input']>;
  schemas?: InputMaybe<Array<Scalars['String']['input']>>;
  selectiveMode?: InputMaybe<SelectiveMode>;
  test?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StartSyncOptions = {
  checkpointExecution?: InputMaybe<Scalars['UUID']['input']>;
  resync?: InputMaybe<Scalars['Boolean']['input']>;
  retryExecution?: InputMaybe<Scalars['UUID']['input']>;
  test?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatsigConfiguration = {
  __typename?: 'StatsigConfiguration';
  streamID: Scalars['String']['output'];
};

export enum StorageType {
  Blob = 'blob',
  PolytomicS3 = 'polytomicS3',
  S3 = 's3'
}

export type StringObj = {
  __typename?: 'StringObj';
  str?: Maybe<Scalars['String']['output']>;
};

export type StripeConfiguration = {
  __typename?: 'StripeConfiguration';
  streamID: Scalars['String']['output'];
};

export type StripeSourceConfiguration = {
  __typename?: 'StripeSourceConfiguration';
  reportingLookback?: Maybe<Scalars['Int']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  bulkSyncStatus: BulkSyncStatus;
  syncStatus: SyncStatus;
};


export type SubscriptionBulkSyncStatusArgs = {
  syncID: Scalars['UUID']['input'];
};


export type SubscriptionSyncStatusArgs = {
  syncID: Scalars['UUID']['input'];
};

export type SupportedBulkMode = {
  __typename?: 'SupportedBulkMode';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  mode: SyncMode;
};

export type SupportedFilterFunction = {
  __typename?: 'SupportedFilterFunction';
  id: FilterFunction;
  label: Scalars['String']['output'];
  multiValue: Scalars['Boolean']['output'];
  requiresValue: Scalars['Boolean']['output'];
  values: Array<CompletionValue>;
};

export type SupportedFilterFunctionsForType = {
  __typename?: 'SupportedFilterFunctionsForType';
  filters: Array<SupportedFilterFunction>;
  type: FieldType;
};

export type SupportedIdentityFunction = {
  __typename?: 'SupportedIdentityFunction';
  compatibleTypes: Array<FieldType>;
  id: IdentityFunction;
  label: Scalars['String']['output'];
  supportsCreateMode: Scalars['Boolean']['output'];
};

export type SupportedMode = {
  __typename?: 'SupportedMode';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  mode: SyncMode;
  requiresIdentity: Scalars['Boolean']['output'];
  supportsFieldSyncMode: Scalars['Boolean']['output'];
  supportsIncremental: Scalars['Boolean']['output'];
  supportsTargetFilters: Scalars['Boolean']['output'];
};

export type SurvicateConfiguration = {
  __typename?: 'SurvicateConfiguration';
  survey: Scalars['String']['output'];
};

export type Sync = {
  __typename?: 'Sync';
  acl: Array<Action>;
  active: Scalars['Boolean']['output'];
  currentExecution?: Maybe<SyncExecution>;
  fields: Array<FieldMapping>;
  filterLogic: Scalars['String']['output'];
  filters: Array<Filter>;
  id: Scalars['UUID']['output'];
  identity?: Maybe<IdentityMapping>;
  lastExecution?: Maybe<SyncExecution>;
  mode?: Maybe<SyncMode>;
  name: Scalars['String']['output'];
  nextExecutionTime?: Maybe<Scalars['Time']['output']>;
  notes: Scalars['String']['output'];
  overrideFields: Array<OverrideFieldMapping>;
  overrides: Array<Override>;
  resyncWarning?: Maybe<Scalars['String']['output']>;
  runAfterBulkSyncs: Array<BulkSync>;
  runAfterSyncs: Array<Sync>;
  schedule?: Maybe<Schedule>;
  syncAllRecords: Scalars['Boolean']['output'];
  tags: Array<PermissionTag>;
  targetConnection?: Maybe<Connection>;
  targetFilterLogic: Scalars['String']['output'];
  targetObject?: Maybe<TargetObject>;
  targetObjectConfiguration: TargetObjectConfiguration;
  targetSearchValues: Scalars['Map']['output'];
  updatedAt?: Maybe<Scalars['Time']['output']>;
  updatedBy?: Maybe<UpdatedBy>;
  willTruncateOnResync: Scalars['Boolean']['output'];
};

export type SyncDeletedSuccessResponse = {
  __typename?: 'SyncDeletedSuccessResponse';
  id: Scalars['UUID']['output'];
};

export type SyncErrorResponse = {
  __typename?: 'SyncErrorResponse';
  error: Scalars['String']['output'];
  usedBy: Array<Sync>;
};

export type SyncExecution = {
  __typename?: 'SyncExecution';
  checkpointExecution?: Maybe<SyncExecution>;
  checkpointable: Scalars['Boolean']['output'];
  completedAt?: Maybe<Scalars['Time']['output']>;
  deleteCount?: Maybe<Scalars['Int']['output']>;
  deletes?: Maybe<SyncRecords>;
  errorCount?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<SyncRecordErrors>;
  executionErrors: Array<SyncExecutionError>;
  formattedDuration: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertCount?: Maybe<Scalars['Int']['output']>;
  inserts?: Maybe<SyncRecords>;
  recordCount?: Maybe<Scalars['Int']['output']>;
  records?: Maybe<SyncRecords>;
  resync: Scalars['Boolean']['output'];
  retryOf?: Maybe<SyncExecution>;
  startedAt?: Maybe<Scalars['Time']['output']>;
  status: ExecutionStatus;
  type: ExecutionTrigger;
  updateCount?: Maybe<Scalars['Int']['output']>;
  updates?: Maybe<SyncRecords>;
  warningCount?: Maybe<Scalars['Int']['output']>;
  warningTypes: Array<SyncWarning>;
  warnings?: Maybe<SyncRecordWarnings>;
};

export type SyncExecutionConnection = {
  __typename?: 'SyncExecutionConnection';
  edges?: Maybe<Array<SyncExecutionEdge>>;
  pageInfo: PageInfo;
};

export type SyncExecutionEdge = {
  __typename?: 'SyncExecutionEdge';
  cursor: Scalars['String']['output'];
  node: SyncExecution;
};

export type SyncExecutionError = {
  __typename?: 'SyncExecutionError';
  error: Scalars['String']['output'];
};

export enum SyncMode {
  Append = 'append',
  Create = 'create',
  Remove = 'remove',
  Replace = 'replace',
  Replicate = 'replicate',
  Snapshot = 'snapshot',
  Update = 'update',
  UpdateOrCreate = 'updateOrCreate'
}

export type SyncPeek = {
  __typename?: 'SyncPeek';
  Active: Scalars['Boolean']['output'];
  Frequency: Scalars['String']['output'];
  LastRunError: Scalars['String']['output'];
  LastRunErrorCount: Scalars['Int']['output'];
  LastRunTimestamp?: Maybe<Scalars['Time']['output']>;
  RunAfterSyncs: Array<RunAfterSyncPeek>;
  SourceConnections: Array<ConnectionPeek>;
  Status: Scalars['String']['output'];
  TargetConnectionID: Scalars['UUID']['output'];
  TargetConnectionName: Scalars['String']['output'];
  TargetConnectionType: Scalars['String']['output'];
  TargetObjectName: Scalars['String']['output'];
  ViewedAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type SyncRecord = {
  __typename?: 'SyncRecord';
  data: Scalars['Map']['output'];
  link?: Maybe<Scalars['String']['output']>;
};

export type SyncRecordErrors = {
  __typename?: 'SyncRecordErrors';
  canRetry: Scalars['Boolean']['output'];
  download?: Maybe<Scalars['String']['output']>;
  executionID: Scalars['UUID']['output'];
  hasData: Scalars['Boolean']['output'];
  recordMessages: Array<SyncRecordMessage>;
};

export type SyncRecordMessage = {
  __typename?: 'SyncRecordMessage';
  identityField: Scalars['String']['output'];
  identityValue: Scalars['Any']['output'];
  message: Scalars['String']['output'];
};

export enum SyncRecordType {
  Deletes = 'deletes',
  Inserts = 'inserts',
  Records = 'records',
  Updates = 'updates'
}

export type SyncRecordWarnings = {
  __typename?: 'SyncRecordWarnings';
  download?: Maybe<Scalars['String']['output']>;
  executionID: Scalars['UUID']['output'];
  hasData: Scalars['Boolean']['output'];
  legacy: Scalars['Boolean']['output'];
  recordMessages: Array<SyncRecordMessage>;
};

export type SyncRecords = {
  __typename?: 'SyncRecords';
  download?: Maybe<Scalars['String']['output']>;
  executionID: Scalars['UUID']['output'];
  hasData: Scalars['Boolean']['output'];
  records: Array<SyncRecord>;
  targetIDProperty?: Maybe<Scalars['String']['output']>;
  type: SyncRecordType;
};

export type SyncRecordsOrContinuation = Continuation | SyncRecords;

export enum SyncState {
  Draft = 'draft',
  New = 'new',
  Saved = 'saved'
}

export type SyncStatus = {
  __typename?: 'SyncStatus';
  execution?: Maybe<SyncExecution>;
  nextExecutionTime?: Maybe<Scalars['Time']['output']>;
};

export type SyncUpdate = {
  fields?: InputMaybe<Array<MappedFieldUpdate>>;
  filterLogic: Scalars['String']['input'];
  filters?: InputMaybe<Array<NewFilter>>;
  id: Scalars['UUID']['input'];
  identity?: InputMaybe<IdentityMappingUpdate>;
  mode?: InputMaybe<SyncMode>;
  name: Scalars['String']['input'];
  newTargetName?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  overrideFields?: InputMaybe<Array<MappedOverrideFieldUpdate>>;
  overrides?: InputMaybe<Array<NewOverride>>;
  runAfterBulkSyncIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  runAfterSyncIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  schedule?: InputMaybe<ScheduleUpdate>;
  syncAllRecords?: InputMaybe<Scalars['Boolean']['input']>;
  targetConfiguration?: InputMaybe<Scalars['Map']['input']>;
  targetConnection: Scalars['ID']['input'];
  targetFilterLogic?: InputMaybe<Scalars['String']['input']>;
  targetObject: Scalars['ID']['input'];
  targetSearchValues?: InputMaybe<Scalars['Map']['input']>;
};

export type SyncViewedResponse = {
  __typename?: 'SyncViewedResponse';
  ok: Scalars['Boolean']['output'];
};

export type SyncWarning = {
  __typename?: 'SyncWarning';
  Link: Scalars['String']['output'];
  Message: Scalars['String']['output'];
  id: Scalars['WarningID']['output'];
};

export type Table = {
  __typename?: 'Table';
  columns?: Maybe<Array<SqlRunnerColumnSchema>>;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TagDeletedSuccessResponse = {
  __typename?: 'TagDeletedSuccessResponse';
  id: Scalars['UUID']['output'];
};

export type TagUsedBy = BulkSync | Connection | Fieldset | Sync;

export type TargetField = {
  __typename?: 'TargetField';
  createable: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  filterFunctions: Array<SupportedFilterFunction>;
  filterable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identityFunctions: Array<SupportedIdentityFunction>;
  isFieldBag: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  remoteFieldTypeId?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  supportsIdentity: Scalars['Boolean']['output'];
  type: FieldType;
  updateable: Scalars['Boolean']['output'];
  values?: Maybe<Array<CompletionValue>>;
};

export type TargetObject = {
  __typename?: 'TargetObject';
  advancedConfiguration?: Maybe<FormDefinition>;
  connection: Connection;
  fields: Array<TargetField>;
  fieldsRefreshedAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  modes: Array<SupportedMode>;
  name: Scalars['String']['output'];
  properties?: Maybe<TargetProperties>;
  scheduleOptions: Array<ScheduleOption>;
};

export type TargetObjectConfiguration = AffinityTargetConfiguration | BlobTargetConfiguration | DatabaseTargetConfiguration | HubspotContactTargetConfiguration | HubspotTargetConfiguration | IntercomTargetConfiguration | MarketoTargetConfiguration | MultiTargetConfiguration | NoConfiguration | SalesforceLeadTargetConfiguration | SalesforceTargetConfiguration | SnowflakeTargetConfiguration | WebhookTargetConfiguration | ZendeskTargetConfiguration;

export type TargetProperties = {
  __typename?: 'TargetProperties';
  doesNotReportOperationCounts: Scalars['Boolean']['output'];
  mappingsNotRequired: Scalars['Boolean']['output'];
  newTargetNameLabel?: Maybe<Scalars['String']['output']>;
  optionalTargetMappings: Scalars['Boolean']['output'];
  primaryMetadataObject: Scalars['String']['output'];
  requiresConfiguration: Scalars['Boolean']['output'];
  supportsFieldCreation: Scalars['Boolean']['output'];
  supportsFieldTypeSelection: Scalars['Boolean']['output'];
  supportsTargetFilters: Scalars['Boolean']['output'];
  targetCreator: Scalars['Boolean']['output'];
};

export type Term = {
  __typename?: 'Term';
  plural: Scalars['String']['output'];
  singular: Scalars['String']['output'];
};

export type TokenPagination = {
  __typename?: 'TokenPagination';
  sendTokenAs: ApiParameter;
  tokenPath: Scalars['JSONPath']['output'];
  tokenTransformation?: Maybe<Scalars['String']['output']>;
};

export type Unconfigured = {
  __typename?: 'Unconfigured';
  ok: Scalars['Boolean']['output'];
};

export type UpdatedBy = {
  __typename?: 'UpdatedBy';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  acl: Array<Action>;
  createPermissions: Array<ResourceType>;
  createdAt: Scalars['Time']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  roles: Array<PermissionRole>;
  status: UserStatus;
  tags: Array<PermissionTag>;
  tagsCanApply: Array<PermissionTag>;
  updatedAt: Scalars['Time']['output'];
};

export type UserRoles = {
  __typename?: 'UserRoles';
  roles: Array<PermissionRole>;
  userID: Scalars['UUID']['output'];
};

export enum UserStatus {
  Active = 'active',
  Deleted = 'deleted',
  Invited = 'invited'
}

export type UserUpdate = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UserVoiceConfiguration = {
  __typename?: 'UserVoiceConfiguration';
  streamID: Scalars['String']['output'];
};

export type ValueLabel = {
  __typename?: 'ValueLabel';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ValueLabelEvent = {
  __typename?: 'ValueLabelEvent';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type VanillaConfiguration = {
  __typename?: 'VanillaConfiguration';
  streamID: Scalars['String']['output'];
};

export type WarehouseConfiguration = {
  __typename?: 'WarehouseConfiguration';
  query?: Maybe<Scalars['String']['output']>;
  schema: Scalars['String']['output'];
  table: Scalars['String']['output'];
  trackingColumns: Array<ModelField>;
};

export type WarehouseDestinationAdvancedConfiguration = {
  __typename?: 'WarehouseDestinationAdvancedConfiguration';
  hardDeletes: Scalars['Boolean']['output'];
  tablePrefix: Scalars['String']['output'];
  truncateExisting: Scalars['Boolean']['output'];
};

export type WarehouseDestinationConfiguration = {
  __typename?: 'WarehouseDestinationConfiguration';
  advanced: WarehouseDestinationAdvancedConfiguration;
  mirrorSchemas?: Maybe<Scalars['Boolean']['output']>;
  schema?: Maybe<Scalars['String']['output']>;
};

export type WebhookTargetConfiguration = {
  __typename?: 'WebhookTargetConfiguration';
  batchSize?: Maybe<Scalars['Int']['output']>;
  metadata: Array<Metadata>;
  recordRequests: Scalars['Boolean']['output'];
};

export type WeeklySchedule = BulkSchedule & Schedule & {
  __typename?: 'WeeklySchedule';
  dayOfWeek: Scalars['String']['output'];
  frequency: Frequency;
  hour: Scalars['String']['output'];
  minute: Scalars['String']['output'];
};

export type WorkOs = {
  __typename?: 'WorkOS';
  acl: Array<Action>;
  id: Scalars['String']['output'];
  tags: Array<PermissionTag>;
};

export type ZendeskConfiguration = {
  __typename?: 'ZendeskConfiguration';
  object: Scalars['String']['output'];
};

export type ZendeskTargetConfiguration = {
  __typename?: 'ZendeskTargetConfiguration';
  replaceRelationships: Scalars['Boolean']['output'];
};

export type ZoomInfoConfiguration = {
  __typename?: 'ZoomInfoConfiguration';
  topics: Array<Scalars['String']['output']>;
  zoomSource: ZoomInfoSource;
};

export type ZoomInfoEnrichmentConfiguration = {
  __typename?: 'ZoomInfoEnrichmentConfiguration';
  object: Scalars['String']['output'];
};

export enum ZoomInfoSource {
  Enrichment = 'enrichment',
  IntentData = 'intentData'
}

export type SyncAfterQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncAfterQueryQuery = { __typename?: 'Query', bulkSyncs: Array<{ __typename?: 'BulkSync', id: any, name: string }>, peekSyncs: Array<{ __typename?: 'SyncPeek', id: any, name: string, TargetConnectionName: string }> };

export type RolesUpdateFragment = { __typename?: 'User', roles: Array<{ __typename?: 'PermissionRole', id: any, name: string, system: boolean }> } & { ' $fragmentName'?: 'RolesUpdateFragment' };

export const RolesUpdateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RolesUpdate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"roles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"system"}}]}}]}}]} as unknown as DocumentNode<RolesUpdateFragment, unknown>;
export const SyncAfterQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SyncAfterQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bulkSyncs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"peekSyncs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"TargetConnectionName"}}]}}]}}]} as unknown as DocumentNode<SyncAfterQueryQuery, SyncAfterQueryQueryVariables>;